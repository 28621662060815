export default {
  Functional: true,
  props: {
    row: Object,
    render: Function,
    index: Number,
    column: {
      type: Object,
      default: null
    }
  },
  render(ctx: {
    row: Object,
    render: Function,
    index: Number,
    column: Object
  }) {
    const params = {
      row: ctx?.row,
      column: ctx?.column,
      index: ctx?.index
    }
    return ctx.render(params)
  }
}
const _lut = []

for (let i = 0; i < 256; i++) {
  _lut[i] = (i < 16 ? '0' : '') + i.toString(16)
}

export function generateUUID() {
  const d0 = (Math.random() * 0xffffffff) | 0
  const d1 = (Math.random() * 0xffffffff) | 0
  const d2 = (Math.random() * 0xffffffff) | 0
  const d3 = (Math.random() * 0xffffffff) | 0
  const uuid =
    _lut[d0 & 0xff] +
    _lut[(d0 >> 8) & 0xff] +
    _lut[(d0 >> 16) & 0xff] +
    _lut[(d0 >> 24) & 0xff] +
    '-' +
    _lut[d1 & 0xff] +
    _lut[(d1 >> 8) & 0xff] +
    '-' +
    _lut[((d1 >> 16) & 0x0f) | 0x40] +
    _lut[(d1 >> 24) & 0xff] +
    '-' +
    _lut[(d2 & 0x3f) | 0x80] +
    _lut[(d2 >> 8) & 0xff] +
    '-' +
    _lut[(d2 >> 16) & 0xff] +
    _lut[(d2 >> 24) & 0xff] +
    _lut[d3 & 0xff] +
    _lut[(d3 >> 8) & 0xff] +
    _lut[(d3 >> 16) & 0xff] +
    _lut[(d3 >> 24) & 0xff]

  // .toLowerCase() here flattens concatenated strings to save heap memory space.
  return uuid.toLowerCase()
}

/**
 *
 * @param {number} num
 * @returns
 */
export function formatTen(num) {
  if (num >= 10) {
    return num
  } else {
    return '0' + num
  }
}

const specialChars = ['/', '\\', ':', '*', '?', '<', '>', '|']

/**
 * @param {string} fileName
 */
export function getBaseName(fileName) {
  let name = fileName
  const pos = fileName.lastIndexOf('.')
  if (pos !== -1) {
    name = fileName.substring(0, pos)
  }
  // return encodeURIComponent(name)
  //return name
  let res = ''
  for (let i = 0; i < name.length; i++) {
    if (specialChars.indexOf(name[i]) < 0) res += name[i]
  }

  return res
}

/**
 *
 * @param {string} fileName
 */
export function getExtension(fileName) {
  let extension = ''
  const pos = fileName.lastIndexOf('.')
  if (pos !== -1) {
    extension = fileName.substring(pos)
  }
  return extension
}

/**
 *
 * @param {string} fileName
 */
export function getFileKey(fileName) {
  const date = new Date()
  const dir = `${date.getFullYear()}/${formatTen(
    date.getMonth() + 1
  )}/${formatTen(date.getDate())}/`

  return (
    dir +
    getBaseName(fileName) +
    '_' +
    generateUUID() +
    '_' +
    date.getTime() +
    getExtension(fileName)
  )
}

export async function openUrl(url, fileName) {
  const response = await fetch(url);
  // 获取资源的数据Blob
  const blob = await response.blob();
  // 创建一个Blob URL
  const blobUrl = window.URL.createObjectURL(blob);
  // 创建a标签并设置属性
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName;
  // 将a标签添加到文档中
  document.body.appendChild(link);
  // 触发点击事件进行下载
  link.click();

  // 清除创建的Blob URL，以释放内存
  window.URL.revokeObjectURL(blobUrl);

  // 从DOM中移除a标签
  link.remove();
}

export async function openUrlTxt(url) {

  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  // 获取响应头中的字符编码
  const contentType = response.headers.get('content-type');
  const charset = contentType && contentType.includes('charset=') ? contentType.split('charset=')[1] : 'utf-8';

  // 使用TextDecoder解码
  const decoder = new TextDecoder(charset);
  const text = decoder.decode(await response.arrayBuffer());

  // 创建一个Blob对象
  const blob = new Blob([text], { type: 'text/plain;charset=' + charset });

  // 创建一个Blob URL
  const blobUrl = window.URL.createObjectURL(blob);

  // 使用Blob URL打开文件
  window.open(blobUrl, '_blank');
}

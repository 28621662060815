<template>
  <el-cascader v-model="stackId" :options="stackArr" :props="stackSelectProps" collapse-tags collapse-tags-tooltip
    :clearable="clearable" style="width:100%" @change="stackChange" filterable>
    <template #default="{ node, data }">
      <div style="display:inline-flex;">
        <span style="float: left;">{{ data.label }}</span>
        <span v-if="data.isAppointment" style=" float: right;color: #a7a2a2;padding-left: 10px;">预约垛位</span>
      </div>
    </template>
  </el-cascader>
</template>

<script setup>
import { selectByStackCloudNew, selectByStackFuture } from '@/api/wms/stock/stock'
import { listStack } from "@/api/wms/house/stack"

const store = useStore()
const props = defineProps({

  multiple: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  modelValue: {
    type: [Object, Number, String],
    default: null
  },
  flag: {
    type: String,
    default: 'selectByStackCloudNew'
  },
  companyCode: {
    type: String,
    default: ''
  },
  productCode: {
    type: String,
    default: ''
  },
  gradeName: {
    type: String,
    default: ''
  },
  filterList: {
    type: Array,
    default: null
  },
  stacks: {
    type: Array,
    default: null
  },
  isGetStack: {
    type: Boolean,
    default: () => {
      return true
    }
  },
  clearable: {
    type: Boolean,
    default: () => {
      return true
    }
  },
  isVarietyCode: {
    type: Boolean,
    default: () => {
      return true
    }
  }
})
const actions = {
  'listStack': listStack,
  'selectByStackCloudNew': selectByStackCloudNew,
  'selectByStackFuture': selectByStackFuture
}
const form = reactive({
  companyCode: props.companyCode,
  varietyCode: '',
  tenantId: '',
  productCode: props.productCode,
  gradeName: props.gradeName
})
const stackId = ref()
const stackArr = ref()
const emit = defineEmits(['change', 'update:modelValue'])
const { modelValue, multiple } = props
const firstStacks = ref()
const stackSelectProps = {
  multiple: props.multiple,
  separator: '/',
  emitPath: false
}
const stackChange = (val) => {
  console.log(val)
  const selectedData = findObjectByValueArray(val, firstStacks.value);
  console.log(selectedData)
  emit('update:modelValue', stackId.value)
  emit('change', { val, selectedData })
}
function findObjectByValueArray(valueArray, options) {
  let result = [];
  if (!Array.isArray(valueArray)) {
    result.push(options.find(option => option.stackId === valueArray))
  } else {
    for (const value of valueArray) {
      result.push(options.find(option => option.stackId === value))
    }
  }
  return result;
}
async function getStackData() {
  const { code, rows } = await actions[props.flag]({
    pageNum: 1, pageSize: 9999, companyCode: form.companyCode, productCode: form.productCode, gradeName: form.gradeName, varietyCode: props.isVarietyCode ? form.varietyCode : '', tenantId: form.tenantId
  })
  if (code === 200) {
    stacksHandler(rows)
  }
}
function stacksHandler(data) {
  firstStacks.value = data
  stackArr.value = []
  data.forEach(item => {
    const existingRoom = stackArr.value.find(room => room.value === item.roomId);
    if (existingRoom) {
      existingRoom.children.push({ value: item.stackId, label: item.stackName, isAppointment: item.isAppointment });
    } else {
      stackArr.value.push({ value: item.roomId, label: item.roomName, children: [{ value: item.stackId, label: item.stackName, isAppointment: item.isAppointment }] });
    }
  });
  console.log(stackArr.value)
  form.stackId = '' + ``
}
onMounted(async () => {
  form.tenantId = store?.getters.tenantId
})
watch(
  () => store.state.variety.itemObj,
  (val) => {
    if (val) {
      form.varietyCode = val.varietyCode
      console.log('isGetStack', props.isGetStack)
      if (props.isGetStack) {
        getStackData()
      }
    }
  },
  {
    immediate: true,
  }
)
watch(
  () => props.stacks,
  (val) => {
    if (val) {
      console.log(val)
      if (val.length >= 0) {
        stacksHandler(val)
      }
    }

  },
)
watch(
  () => props.companyCode,
  (val) => {
    if (form.varietyCode !== 'CF') {
      stackArr.value = []
    }
    if (val) {
      form.companyCode = props.companyCode
      console.log(val)
      if (props.productCode) {
        getStackData()
      }
    }
  },
)
watch(
  () => props.productCode,
  (val) => {
    form.productCode = props.productCode
    form.gradeName = props.gradeName
    console.log(val)
    if (val) {
      getStackData()
    }
  },
)
watchEffect(() => {
  if (props.modelValue) {
    stackId.value = props.modelValue
  } else {
    stackId.value = ''
  }
})
defineExpose({
  stacksHandler
});
</script>
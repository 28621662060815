<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SelectProductName',
})
</script>
<template>
  <el-select v-model="productName" filterable clearable placeholder="请选择" @change="selectProduct" value-key="productCode">
    <el-option v-for="(data, index) in productList" :label="data.productName" :value="data"
      :key="data.productCode"></el-option>
  </el-select>
</template>
<script setup name="SelectProductName" lang="ts">
import { reactive, ref, watchEffect, watch } from 'vue'
import { useStore } from 'vuex'
import { selectProductByStock } from '@/api/wms/stock/stock'
import { receiptRegisterProductList, productListByCompanyId } from "@/api/wms/futures/receiptRegister"
const store = useStore()
const emit = defineEmits<{
  (e: 'change', value: string): void
  (e: 'update:modelValue', value: string): void
}>()

const props = defineProps({
  modelValue: [Number, String, Object, Array],
  companyCode: {
    type: String,
    default: ''
  },
  companyId: {
    type: String,
    default: null
  },
  flag: {
    type: String,
    default: 'selectProductByStock'
  },
});
const actions: any = {
  'selectProductByStock': selectProductByStock,
  'receiptRegisterProductList': receiptRegisterProductList,
  'productListByCompanyId': productListByCompanyId
}
const productList: any = ref([])
const productCode = ref('')
const productName = ref('')
const queryParams = reactive({
  pageNum: 1,
  pageSize: 9999,
  companyCode: props.companyCode,
  companyId: props.companyId,
  varietyCode: store.state.variety.varietyId,
  tenantId: store.state.user.tenantId,
  allowOperate: '1',
})

const getProduct = async () => {
  const { code, rows }: any = await actions[props.flag](queryParams)
  if (code && code === 200) {
    productList.value = rows
    if (productCode.value) {
      const product = productList.value.find((item: any) => item.productCode === productCode.value)
      if (product) {
        productName.value = product.productName
      }
    }
  }
}

watch(
  () => props.companyCode,
  (value) => {
    queryParams.companyCode = value;
    productCode.value = ''
    productName.value = ''
    productList.value = []
    if (value) {
      getProduct();
    }
  },
)
watch(
  () => props.companyId,
  (value) => {
    if (value) {
      queryParams.companyId = value;
      productCode.value = ''
      productName.value = ''
      getProduct();
    }
  },
)
watch(
  () => store.state.variety.varietyId,
  (value) => {
    if (value && queryParams.companyCode) {
      queryParams.varietyCode = value;
      productCode.value = ''
      productName.value = ''
      getProduct();
    }
  },
)

watchEffect(() => {
  console.log('watchEffect', productCode.value)
  emit("update:modelValue", productCode.value);
})
watchEffect(() => {
  if (props.modelValue) {
    productCode.value = props.modelValue + ''
    console.log(productCode.value)
  } else {
    productCode.value = ''
  }
})
const selectProduct = (val: any) => {
  console.log(val.productCode)
  productName.value = val.productName
  emit("update:modelValue", val.productCode);
  emit("change", val);
}
</script>
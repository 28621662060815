import request from '@/utils/request'

// 查询库房管理列表
export function listRoom(query) {
  return request({
    url: '/wms/room/list',
    method: 'get',
    params: query
  })
}



// 查询库房管理列表
export function roomList(query) {
  return request({
    url: '/wms/room/listAll',
    method: 'get',
    params: query
  })
}

// 查询库房管理列表
export function listAreaRoom(query) {
  return request({
    url: '/wms/room/areaRooms',
    method: 'get',
    params: query
  })
}

// 查询库房管理详细
export function getRoom(id) {
  return request({
    url: '/wms/room/' + id,
    method: 'get'
  })
}

// 查询库房管垛位数量
export function getStackCountByRoomId(id) {
  return request({
    url: '/wms/house/getStackCountByRoomId/' + id,
    method: 'get'
  })
}

// 异步下载垛位二维码
export function downLoadQrcodePdfOffline(id) {
  return request({
    url: '/wms/house/downLoadQrcodePdfOffline/' + id,
    method: 'get'
  })
}


// 获取历史下载记录
export function downLoadQrcodePdfHistory(id) {
  return request({
    url: '/wms/house/downLoadQrcodePdfHistory/' + id,
    method: 'get'
  })
}

// 新增库房管理
export function addRoom(data) {
  return request({
    url: '/wms/room',
    method: 'post',
    data: data
  })
}

// 修改库房管理
export function updateRoom(data) {
  return request({
    url: '/wms/room',
    method: 'put',
    data: data
  })
}

// 删除库房管理
export function delRoom(id) {
  return request({
    url: '/wms/room/' + id,
    method: 'delete'
  })
}

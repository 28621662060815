<template>
  <div class="upload-file">
    <el-upload
        v-if="showButton"
        multiple
        :action="host"
        :before-upload="handleBeforeUpload"
        :file-list="fileList"
        :limit="limit"
        :on-error="handleUploadError"
        :on-exceed="handleExceed"
        :on-success="handleUploadSuccess"
        :show-file-list="false"
        :data="objectData"
        :http-request="handleUpload"
        class="avatar-uploader upload-file-uploader"
        ref="upload"
    >
      <!-- 上传按钮 -->
      <el-icon v-if="showButton" class="avatar-uploader-icon" style="font-size: 24px">
        <Plus/>
      </el-icon>
      <!-- <el-button type="primary" v-if="showButton">选取文件</el-button> -->
      <!-- 上传提示 -->
      <div class="el-upload__tip" v-if="showTip">
        请上传
        <template v-if="fileSize">
          大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b>
        </template>
        <template v-if="fileType">
          格式为 <b style="color: #f56c6c">{{ fileType.join('/') }}</b>
        </template>
        的文件
      </div>
    </el-upload>

    <!-- 文件列表 -->
    <transition-group
        class="upload-file-list el-upload-list el-upload-list--text"
        name="el-fade-in-linear"
        tag="ul"
    >
      <li
          :key="file.uid"
          class="el-upload-list__item ele-upload-list__item-content"
          v-for="(file, index) in fileList"
      >
        <!--<el-link :href="file.url" :underline="false" target="_blank">-->
        <span class="el-icon-document overflow-hidden">
          {{ getFileName(file.name) }}
        </span>
        <!--</el-link>-->
        <div class="ele-upload-list__item-content-action">
          <el-icon
              :size="size"
              color="#409EFC"
              style="cursor: pointer; margin-right: 10px"
              title="点击预览"
              @click="handleOpen(file, fileList)"
          >
            <View
            />
          </el-icon>
          <el-icon
              :size="size"
              color="#0b8235"
              style="cursor: pointer; margin-right: 10px"
              title="点击下载"
              @click="handleDown(file)"
          >
            <Download
            />
          </el-icon>
          <el-icon
              :underline="false"
              v-if="showButton"
              :size="size"
              color="#f4364c"
              style="cursor: pointer"
              title="点击删除"
              @click="handleDelete(index)"
          >
            <delete
            />
          </el-icon>
        </div>
      </li>
    </transition-group>
  </div>
</template>

<script setup>
import {getFilePath, getSignature, uploadFile} from '@/api/wms/file'
import {preview} from 'vue3-preview-image'
import {ref, reactive, computed, watch, getCurrentInstance} from 'vue'
import {openUrl, openUrlTxt} from './util'

const props = defineProps({
  modelValue: [String, Object, Array],
  // 数量限制
  limit: {
    type: Number,
    default: 5
  },
  // 大小限制(MB)
  fileSize: {
    type: Number,
    default: 10
  },
  // 文件类型, 例如['png', 'jpg', 'jpeg']
  fileType: {
    type: Array,
    default: () => [
      'zip',
      'rar',
      'jpg',
      'gif',
      'png',
      'jpeg',
      'tif',
      'bmp',
      'doc',
      'xls',
      'docx',
      'xlsx',
      'ppt',
      'txt',
      'exe',
      'db',
      'pdf',
      'mp4'
    ]
  },
  // 是否显示提示
  isShowTip: {
    type: Boolean,
    default: true
  },
  isShowButton: {
    type: Boolean,
    default: true
  },
  size: {
    type: String,
    default: 'small'
  },
  needTransfer: {
    type: Boolean,
    default: true
  }
})

const {proxy} = getCurrentInstance()
const emit = defineEmits()
const number = ref(0)
const uploadList = ref([])
const fileList = ref([])
const showTip = computed(
    () => props.isShowTip && (props.fileType || props.fileSize)
)
const showButton = computed(() => props.isShowButton)

const host = ref('')
const objectData = reactive({
  OSSAccessKeyId: '',
  policy: '',
  signature: '',
  callback: ''
})

watch(
    () => props.modelValue,
    (val) => {
      if (val) {
        let temp = 1
        // 首先将值转为数组
        const list = Array.isArray(val) ? val : props.modelValue.split(',')
        // 然后将数组转为对象数组
        fileList.value = list.map((item) => {
          if (typeof item === 'string') {
            item = {name: item, url: item}
          }
          if (!props.needTransfer) {
            item.ossUrl = item.url
          }
          if (item.url.startsWith('http://') || item.url.startsWith('https://')) {
            item.url = item.url.split('.com/')[1]
          }
          item.uid = item.uid || new Date().getTime() + temp++
          return item
        })
      } else {
        fileList.value = []
        return []
      }
    },
    {deep: true, immediate: true}
)

// 上传前校检格式和大小
async function handleBeforeUpload(file) {
  // 校检文件类型
  if (props.fileType.length) {
    let fileExtension = ''
    if (file.name.lastIndexOf('.') > -1) {
      fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1)
    }
    const isTypeOk = props.fileType.some((type) => {
      if (file.type.indexOf(type) > -1) return true
      if (fileExtension && fileExtension.indexOf(type) > -1) return true
      return false
    })
    if (!isTypeOk) {
      proxy.$modal.msgError(
          `文件格式不正确, 请上传${props.fileType.join('/')}格式文件!`
      )
      return false
    }
  }
  // 校检文件大小
  if (props.fileSize) {
    const isLt = file.size / 1024 / 1024 < props.fileSize
    if (!isLt) {
      proxy.$modal.msgError(`上传文件大小不能超过 ${props.fileSize} MB!`)
      return false
    }
  }

  try {
    const res = await getSignature()
    objectData.OSSAccessKeyId = res.data.accessid
    host.value = res.data.host
    objectData.policy = res.data.policy
    objectData.signature = res.data.signature
    objectData.callback = res.data.callback
  } catch (e) {
    return false
  }

  proxy.$modal.loading('正在上传文件，请稍候...')
  number.value++
  return true
}

async function handleUpload(e) {
  return uploadFile(e, host.value)
}

// 文件个数超出
function handleExceed() {
  proxy.$modal.msgError(`上传文件数量不能超过 ${props.limit} 个!`)
}

// 上传失败
function handleUploadError(err) {
  proxy.$modal.msgError('上传文件失败')
}

// 上传成功回调
function handleUploadSuccess(res, file) {
  uploadList.value.push({
    name: res.data.name,
    url: res.data.url,
    ossUrl: res.data.ossUrl
  })
  if (uploadList.value.length === number.value) {
    fileList.value = fileList.value.concat(uploadList.value)
    uploadList.value = []
    number.value = 0
    emit('update:modelValue', listToString(fileList.value))
    proxy.$modal.closeLoading()
  }
}

// 删除文件
function handleDelete(index) {
  fileList.value.splice(index, 1)
  emit('update:modelValue', listToString(fileList.value))
}

// 下载文件
async function handleDown(fileItem) {
  if (!fileItem.ossUrl) {
    const res = await getFilePath(fileItem.url)
    fileItem.ossUrl = res.data
  }
  //window.open(fileItem.ossUrl)
  openUrl(fileItem.ossUrl, fileItem.name)
}

// 预览文件
async function handleOpen(fileItem, fileList) {
  // current,fileList
  const type = matchFileType(fileItem.name)
  if (type === 'image') {
    let imgArr = []
    for (let item in fileList) {
      if (matchFileType(fileList[item].url) === 'image') {
        imgArr.push(fileList[item])
      }
    }

    await Promise.all(
        imgArr.map(async (item) => {
          if (!item.ossUrl) {
            const res = await getFilePath(item.url)
            item.ossUrl = res.data
          }
        })
    )

    //获取当前选择图片的索引位置
    preview(
        imgArr.findIndex((item) => item.ossUrl === fileItem.ossUrl),
        imgArr.map((item) => item.ossUrl)
    )
  } else {
    if (!fileItem.ossUrl) {
      const res = await getFilePath(fileItem.url)
      fileItem.ossUrl = res.data
    }
    let previewUrl = fileItem.ossUrl

    if (type === 'video') {
      window.open(previewUrl)
      return
    }
    if (type==='pdf'){
      window.open(previewUrl);
      return;
    }
    if(type==='txt'){
      openUrlTxt(previewUrl);
      return;
    }

    let nowUrl =
        'https://view.officeapps.live.com/op/view.aspx?src=' +
        encodeURIComponent(previewUrl)
    // console.log("3--------"+nowUrl)
    console.log(nowUrl);
    window.open(nowUrl, '_blank');
  }
}

// 获取文件名称
function getFileName(name, all = false) {
  if (name.lastIndexOf('/') > -1) {
    //系统生成的附件序号踢出掉，显示简短附件名称
    const res = name
        .slice(name.lastIndexOf('/') + 1)
        .replace(name.slice(name.indexOf('_'), name.lastIndexOf('.')), '')
    if (!all && res.length > 10) {
      return res.substring(0, 10) + '...'
    } else {
      return res
    }
  } else {
    return ''
  }
}

// 对象转成指定字符串分隔
function listToString(list, separator) {
  let strs = ''
  separator = separator || ','
  for (let i in list) {
    if (undefined !== list[i].url) {
      strs += list[i].url + separator
    }
  }
  return strs != '' ? strs.substr(0, strs.length - 1) : ''
}

/**
 * 根据文件名后缀区分 文件类型
 *
 * @param: fileName - 文件名称
 * @param: 数据返回 1) 无后缀匹配 - false
 * @param: 数据返回 2) 匹配图片 - image
 * @param: 数据返回 3) 匹配 txt - txt
 * @param: 数据返回 4) 匹配 excel - excel
 * @param: 数据返回 5) 匹配 word - word
 * @param: 数据返回 6) 匹配 pdf - pdf
 * @param: 数据返回 7) 匹配 ppt - ppt
 * @param: 数据返回 8) 匹配 视频 - video
 * @param: 数据返回 9) 匹配 音频 - radio
 * @param: 数据返回 10) 其他匹配项 - other
 */
function matchFileType(fileName) {
  // 后缀获取
  let suffix = ''
  // 获取类型结果
  let result = ''
  if (!fileName) return false
  try {
    // 截取文件后缀
    suffix = fileName.substr(fileName.lastIndexOf('.') + 1, fileName.length)
    // 文件后缀转小写，方便匹配
    suffix = suffix.toLowerCase()
  } catch (err) {
    suffix = ''
  }
  // fileName无后缀返回 false
  if (!suffix) {
    result = false
    return result
  }
  if (suffix.length > 50) {
    return 'image'
  }
  let fileTypeList = [
    // 图片类型
    {typeName: 'image', types: ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'tif']},
    // // 文本类型
    {typeName: 'txt', 'types': ['txt']},
    // // excel类型
    {typeName: 'excel', 'types': ['xls', 'xlsx']},
    {typeName: 'word', 'types': ['doc', 'docx']},
    {typeName: 'pdf', 'types': ['pdf']},
    {typeName: 'ppt', 'types': ['ppt']},
    {typeName: 'exe', 'types': ['exe']},
    {typeName: 'db', 'types': ['db']},
    // // 视频类型
    {typeName: 'video', types: ['mp4', 'm2v', 'mkv']}
    // // 音频
    // {'typeName': 'radio', 'types': ['mp3', 'wav', 'wmv']}
  ]
  // let fileTypeList = ['image', 'txt', 'excel', 'word', 'pdf', 'video', 'radio']
  for (let i = 0; i < fileTypeList.length; i++) {
    const fileTypeItem = fileTypeList[i]
    const typeName = fileTypeItem.typeName
    const types = fileTypeItem.types
    result = types.some(function (item) {
      return item === suffix
    })
    if (result) {
      return typeName
    }
  }
  return 'other'
}
</script>

<style scoped lang="scss">
.upload-file-uploader {
  margin-bottom: 5px;
}

.upload-file-list .el-upload-list__item {
  border: 0px solid #e4e7ed;
  line-height: 2;
  margin-bottom: 2px;
  position: relative;
}

.upload-file-list .ele-upload-list__item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
}

.ele-upload-list__item-content-action .el-link {
  margin-right: 10px;
}

.upload-file ul {
  padding: 0;
  margin: 0;
}

.upload-file li {
  padding: 0;
  margin: 0;
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

// .el-icon.avatar-uploader-icon {
//   font-size: 100px;
//   color: #8c939d;
//   width: 178px;
//   height: 178px;
//   text-align: center;
//   border:1px solid grey;
// }
// .overflow-hidden {
//   text-overflow: ellipsis;
//   overflow: hidden;
//   white-space: nowrap;
// }
</style>

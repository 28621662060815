<template>
  <div class="navbar">

    <top-nav id="topmenu-container" class="topmenu-container" v-if="$store.state.settings.topNav" />

    <div class="right-menu">
      <el-badge :value="messageList.length" style="margin-right: 14px;">
        <el-popover placement="bottom" :width="450">
          <template #reference>
            <el-icon ><Bell /></el-icon>
          </template>
          <div v-if="messageList != null && messageList.length > 0">
            <el-table :data="messageList">
              <el-table-column width="150" prop="title" label="标题" align="center">
                <template #default="{ row }">
                  <el-tag effect="plain" :type="row.grade">{{row.title}}</el-tag>
                </template>
              </el-table-column>
              <el-table-column width="170" prop="createTime" label="时间" align="center" />
              <el-table-column label="操作" align="center">
                <template #default="{ row }">
                  <el-button type="text" @click="goMsgDetail(row)">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-else>
            <el-empty description="暂无信息" :image-size="100"/>
          </div>
        </el-popover>
      </el-badge>
      <el-select v-model="store.state.variety.varietyId" class="variety" placeholder="请选择品种" @change="onVarietyIdChange">
        <el-option v-for="item in varietyList" :key="item.value" :value="item.value"
          :label="item.value + '-' + item.label"></el-option>
      </el-select>

      <template v-if="getters.device !== 'mobile'">
        <el-tooltip content="巡检系统" effect="dark" placement="bottom">
          <WmsPatrol id="wmsPatrol" />
        </el-tooltip>
        <el-tooltip content="三维视图" effect="dark" placement="bottom">
          <svg-icon icon-class="view3d" @click="toPage"></svg-icon>
        </el-tooltip>
        <el-tooltip content="物联网" effect="dark" placement="bottom">
          <svg-icon @click="toSdnPage" icon-class="sdn"></svg-icon>
        </el-tooltip>
        <screenfull id="screenfull" />
        <el-tooltip content="布局大小" effect="dark" placement="bottom">
          <size-select id="size-select" />
        </el-tooltip>
      </template>
      <el-dropdown trigger="hover">
        <div class="user-info">
          <span>手机访问</span>
          <el-icon>
            <CaretBottom />
          </el-icon>
        </div>
        <template #dropdown>
          <el-dropdown-item>
            <el-dropdown placement='left-start'>
              <div class="el-dropdown-menu__item itemClass">
                <img :src="logoxcx" style="width: 23px; height: 28px;margin-right: 10px;">
                微信小程序
              </div>
              <template #dropdown>
                <el-dropdown-item>
                  <img class="itemClass imgClass" :src="qrcodexcx" alt="QR Code">
                </el-dropdown-item>
              </template>
            </el-dropdown>
          </el-dropdown-item>
          <el-dropdown-item>
            <el-dropdown placement='left-start'>
              <div class="el-dropdown-menu__item itemClass">
                <img :src="logogzh"
                  style="width: 23px; height: 20px;margin-right: 10px;margin-top: 4px;margin-bottom: 4px">微信公众号
              </div>
              <template #dropdown>
                <el-dropdown-item>
                  <img class="itemClass imgClass" :src="qrcodegzh" alt="QR Code">
                </el-dropdown-item>
              </template>
            </el-dropdown>
          </el-dropdown-item>
        </template>
      </el-dropdown>
      <!--用户信息-->
      <el-dropdown @command="handleCommand" trigger="click">
        <div class="user-info">
          <!-- <el-badge :value="12" class="item" @click.stop="toMessage">
            <el-avatar class="user-info-avatar">{{ nameFirstLetter }}</el-avatar>
          </el-badge> -->
          <el-avatar class="user-info-avatar">{{ nameFirstLetter }}</el-avatar>
          <span>{{ getters.name }}</span>
          <el-icon>
            <CaretBottom />
          </el-icon>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <router-link to="/user/profile">
              <el-dropdown-item>个人中心</el-dropdown-item>
            </router-link>
            <el-dropdown-item command="setLayout">
              <span>布局设置</span>
            </el-dropdown-item>
            <el-dropdown-item divided command="logout">
              <span>退出登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script setup>
import { ElMessageBox } from "element-plus"
import TopNav from "@/components/TopNav"
import Screenfull from "@/components/Screenfull"
import SizeSelect from "@/components/SizeSelect"
import WmsPatrol from "@/components/Wms/Patrol"
import { useVarieties } from "@/hooks/index"
import { computed, ref } from "vue"
import { useRouter } from 'vue-router'
import qrcodegzh from "@/assets/logo/qrcode_official.jpg"
import qrcodexcx from "@/assets/logo/qrcode_program.jpg"
import logoxcx from "@/assets/logo/logo-zsyc.png"
import logogzh from "@/assets/logo/logo.png"

import {listMessage, readMessage} from "@/api/system/message"
const store = useStore()
const router = useRouter()
const getters = computed(() => store.getters)
const nameFirstLetter = computed(() => store.getters.name.substring(0, 1).toUpperCase())
const { varietyList } = useVarieties(() => {
  store.commit("variety/setVarietyList", varietyList.value)
  const idx = varietyList.value.findIndex((item) => {
    return item.value === store.state.variety.varietyId
  })

  if (store.state.variety.varietyId === "" || idx < 0) {
    store.commit("variety/changeVarietyId", varietyList.value[0].value)
  }
})
const messageList = ref([])

function onVarietyIdChange(varietyId) {
  store.commit("variety/changeVarietyId", varietyId)
}

function toggleSideBar() {
  store.dispatch("app/toggleSideBar")
}
const toMessage = () => {
  router.push({
    path: '/system/message'
  })
}
function toPage(e) {
  window.location.href = "/3d/cf/index.html"
  // let warehouse = JSON.parse(localStorage.getItem('warehouse')) || '{}';
  // if(warehouse.variety.includes("CF_|_棉花")) {
  //   //proxy.$router.push({ path: "/3d/cf/index.html"});
  //   window.location.href = "/3d/cf/index.html";
  // } else if(warehouse.variety.includes("TA_|_PTA")) {
  //   //proxy.$router.push({ path: "/3d/ta/index.html"});
  //   window.location.href = "/3d/ta/index.html";
  // }
}

function toSdnPage(e) {
  window.location.href = "/sdn/index.html"
}

function handleCommand(command) {
  switch (command) {
    case "setLayout":
      setLayout()
      break
    case "logout":
      logout()
      break
    default:
      break
  }
}

function logout() {
  ElMessageBox.confirm("确定注销并退出系统吗？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      store.dispatch("LogOut").then(() => {
        location.href = "/index"
      })
    })
    .catch(() => { })
}

const emits = defineEmits(["setLayout"])
function setLayout() {
  emits("setLayout")
}

getMessage()
function getMessage() {
  listMessage({status: '0'}).then(res => {
    messageList.value = res.data
  })
}

function goMsgDetail(row) {
  readMessage(row.id).then(res => {
    getMessage()
    router.push(row.path)
  })
}
</script>

<style lang="scss" scoped>
.itemClass {
  padding-left: 0 !important;
  padding-right: 0 !important
}

.imgClass {
  width: 150px;
  height: 150px;
}

.navbar {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .topmenu-container {
    position: absolute;
    left: 50px;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    height: 50px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;

    .variety {
      width: 160px;
    }

    .user-info {
      display: flex;
      align-items: center;
      font-size: 14px;
      gap: 5px;

      &-avatar {
        width: 30px;
        height: 30px;
        background-color: #337FFF;
      }
    }
  }
}
</style>

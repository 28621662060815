<template>
  <div class="content-header">
    <div class="content-header-title">
      <slot name="title">
        {{  title }}
      </slot>
    </div>
    <div class="content-header-right">
      <slot name="right">
        <!-- <el-button type="primary" icon="Plus">
          新增
        </el-button>
        <el-button icon="Download">
          导出
        </el-button>
        <el-button icon="Upload">
          导入
        </el-button> -->
      </slot>
    </div>
  </div>
</template>
<script setup name="ContentHeader">
import { useRoute } from 'vue-router'
const route = useRoute()
const title = ref(route?.meta?.title)
</script>
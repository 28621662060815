<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ShowPDF',
})
</script>
<template>
  <PDFView v-if="url" :pdfUrl="url" />
</template>
<script setup name="ShowPDF" lang="ts">
import PDFView from '@/components/UploadFile/pdfPreview.vue'
const props = defineProps({
  url: {
    type: String,
    default: ''
  }
})
</script>

<script lang="tsx">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TableList',
})
</script>
<template>
  <el-table :ref="'tableRef' + new Date().getTime()" :data="data" :border="border" show-overflow-tooltip
    :highlight-current-row="highlightCurrentRow" @selection-change="handleSelectionChange" @row-click="handleRowClick">
    <el-table-column v-if="selection" type="selection" fixed="left" width="50" />
    <el-table-column v-if="index" type="index" label="序号" fixed="left" width="50" />
    <el-table-column v-for="(col, index) in columns" :key="col.prop" :prop="col.prop" :label="col.label"
      :width="col.width" :fixed="col.fixed" show-overflow-tooltip>
      <template #default="{ row }">
        <template v-if="'render' in col">
          <RenderRow :row="row" :column="col" :index="index" :render="col.render"></RenderRow>
        </template>
        <template v-else-if="'slot' in col">
          <slot :row="row" :column="col" :index="index" :name="col.slot"></slot>
        </template>
        <template v-else>
          {{ row[col.prop] }}
        </template>
      </template>
    </el-table-column>
  </el-table>
</template>
<script setup name="TableList" lang="tsx">
import { watch, ref } from 'vue'
import { useStore } from 'vuex'
import RenderRow from './RenderRow'

interface IRowsItem {
  prop: string
  label: string
  width: number
  sortable: boolean
  fixed: boolean
  render: Function,
  slot: String
}
const store = useStore()
const emits = defineEmits(['setSelectItems'])
const props = defineProps({
  size: {
    type: String,
    default: 'small'
  },
  border: {
    type: Boolean,
    default: false
  },
  highlightCurrentRow: {
    type: Boolean,
    default: true
  },
  // 索引
  index: {
    type: Boolean,
    default: true
  },
  // 复选
  selection: {
    type: Boolean,
    default: true
  },
  // 表格列
  columns: {
    default: () => {
      return []
    },
    type: Array<IRowsItem>
  },
  data: {
    type: Array,
    default: () => {
      return []
    }
  },
})
const handleSelectionChange = (val: IRowsItem[]) => {
  emits('setSelectItems', val)
}
const handleRowClick = (val: IRowsItem) => {
  // console.log(val, 3333333)
}
const countUnit = ref()
const weightUnit = ref()
watch(
  () => store.state.variety.itemObj,
  (itemObj) => {
    if (itemObj) {
      countUnit.value = itemObj.goodsCountUnit
      weightUnit.value = itemObj.goodsWeightUnit
    }
  },
  {
    immediate: true,
  }
)
</script>
<style scoped lang="scss"></style>
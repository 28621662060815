<template>
  <div class="pdf-wrap">
    <vue-pdf-embed :source="{
      cMapUrl: 'https://unpkg.com/pdfjs-dist@4.0.379/cmaps/',
      cMapPacked: true,
      url: state.source,
    }" :style="`transform:scale(${state.scale})`" class="vue-pdf-embed" :page="state.pageNum" />
    <div class="page-tool">
      <div class="page-tool-item" @click="lastPage">上一页</div>
      <div class="page-tool-item" @click="nextPage">下一页</div>
      <div class="page-tool-item">{{ state.pageNum }}/{{ state.numPages }}</div>
      <div class="page-tool-item" @click="pageZoomOut">放大</div>
      <div class="page-tool-item" @click="pageZoomIn">缩小</div>
      <el-link class="page-tool-item" style="color:#fff" icon="Download" @click="downloadFile()">
        下载
      </el-link>
    </div>
  </div>
</template>
<script setup lang="ts">
import { reactive, ref, onMounted, computed, watch } from "vue";
import VuePdfEmbed from "vue-pdf-embed"
import { createLoadingTask } from 'vue3-pdfjs/esm'; // 获得总页数
const props = defineProps({
  pdfUrl: {
    type: String,
    required: true
  }
})
const pdfView = ref()
const state = reactive({
  source: '',
  pageNum: 1,
  scale: 1, // 缩放比例
  numPages: 0, // 总页数
});

watch(() => props.pdfUrl,
  (value) => {
    state.source = value
    state.pageNum = 1
    const loadingTask = createLoadingTask(state.source);
    console.log(loadingTask)
    loadingTask.promise.then((pdf: { numPages: number }) => {
      state.numPages = pdf.numPages;
    });
    setTimeout(() => {//如果是特殊字体，就需要延时刷新
      pageZoomOut()
      pageZoomIn()
    }, 100)
  },
  {
    immediate: true,
  })
const downloadFile = async () => {
  const link = document.createElement('a')
  link.href = state.source
  link.target = '_blank'
  link.click()
};
function lastPage() {
  if (state.pageNum > 1) {
    state.pageNum -= 1;
  }
}
function nextPage() {
  if (state.pageNum < state.numPages) {
    state.pageNum += 1;
  }
}
function pageZoomOut() {
  if (state.scale < 2) {
    state.scale += 0.1;
  }
}
function pageZoomIn() {
  if (state.scale > 1) {
    state.scale -= 0.1;
  }
}
</script>
<style lang="css" scoped>
.pdf-preview {
  position: relative;
  height: 100vh;
  padding: 20px 0;
  box-sizing: border-box;
}

.pdf-wrap {
  overflow-y: auto;
  overflow-x: hidden;
}

.vue-pdf-embed {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.page-tool {
  position: absolute;
  bottom: 35px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  background: rgb(66, 66, 66);
  color: white;
  border-radius: 19px;
  z-index: 100;
  cursor: pointer;
  margin-left: 50%;
  transform: translateX(-50%);
}

.page-tool-item {
  padding: 8px 15px;
  padding-left: 10px;
  cursor: pointer;
}
</style>
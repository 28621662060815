import {
  createWebHistory,
  createRouter
} from 'vue-router'
import Layout from '@/layout'

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
export const constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: () => import('@/views/redirect/index.vue'),
  },],
},
{
  path: '/login',
  component: () => import('@/views/login'),
  hidden: true,
},{
    path: '/proxyLoad',
    component: () => import('@/views/proxyLoad'),
    hidden: true,
  },
//  {
//    path: '/signature',
//    component: () => import('@/views/signature/index'),
//    hidden: true
//  },
//  {
//    path: '/goodInfo',
//    component: () => import('@/views/goodInfo/index'),
//    hidden: true
//  },
//  {
//    path: '/register',
//    component: () => import('@/views/register'),
//    hidden: true
//  },
{
  path: '/:pathMatch(.*)*',
  component: () => import('@/views/error/404'),
  hidden: true,
},
{
  path: '/401',
  component: () => import('@/views/error/401'),
  hidden: true,
},
{
  path: '',
  component: Layout,
  redirect: '/index',
  hidden: false,
  fullPath: '/index',
  meta: {
    title: '首页',
    icon: 'main',
    affix: true
  },
  children: [{
    path: '/index',
    component: () => import('@/views/index'),
    name: 'Index',
    meta: {
      title: '首页',
      icon: 'main',
      affix: true
    },
  },],
},
{
  path: '/user',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'profile',
    component: () => import('@/views/system/user/profile/index'),
    name: 'Profile',
    meta: {
      title: '个人中心',
      icon: 'user'
    },
  },],
},
{
  path: '/system/user-auth',
  component: Layout,
  hidden: true,
  children: [{
    path: 'role/:userId(\\d+)',
    component: () => import('@/views/system/user/authRole'),
    name: 'AuthRole',
    meta: {
      title: '分配角色',
      activeMenu: '/system/user'
    },
  },],
},
{
  path: '/system/role-auth',
  component: Layout,
  hidden: true,
  children: [{
    path: 'user/:roleId(\\d+)',
    component: () => import('@/views/system/role/authUser'),
    name: 'AuthUser',
    meta: {
      title: '分配用户',
      activeMenu: '/system/role'
    },
  },],
},
{
  path: '/system/dict-data',
  component: Layout,
  hidden: true,
  children: [{
    path: 'index/:dictId(\\d+)',
    component: () => import('@/views/system/dict/data'),
    name: 'Data',
    meta: {
      title: '字典数据',
      activeMenu: '/system/dict'
    },
  },],
},
{
  path: '/monitor/job-log',
  component: Layout,
  hidden: true,
  children: [{
    path: 'index',
    component: () => import('@/views/monitor/job/log'),
    name: 'JobLog',
    meta: {
      title: '调度日志',
      activeMenu: '/monitor/job'
    },
  },],
},
{
  path: '/tool/gen-edit',
  component: Layout,
  hidden: true,
  children: [{
    path: 'index/:tableId(\\d+)',
    component: () => import('@/views/tool/gen/editTable'),
    name: 'GenEdit',
    meta: {
      title: '修改生成配置',
      activeMenu: '/tool/gen'
    },
  },],
},
]

const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  },
})
// 添加全局的 onError 错误处理函数  
router.onError((error) => {
  console.error('捕获到路由导航过程中的错误:', error)
  // 这里你可以根据需要添加错误处理逻辑  
  // 比如发送错误报告到服务器，或者显示一个错误提示给用户  
});

export default router
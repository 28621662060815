<template>
  <!-- 父页面引入 -->
  <!-- <outboundGoods v-model="modelValue" @submitForm="submitForm" :companyCode="companyCode"></outboundGoods> -->
  <el-dialog
    :before-close="cancel"
    :close-on-click-modal="false"
    title="添加商品"
    v-model="modelValue"
    width="1000px"
    append-to-body
  >
    <el-form
      :model="queryParams"
      ref="queryRef"
      :inline="true"
      label-width="100px"
    >
      <el-form-item label="商品名称" prop="productName">
        <el-input
          v-model="queryParams.productName"
          placeholder="请输入商品名称"
          clearable
        />
      </el-form-item>
      <el-form-item label="商品编码" prop="productCode">
        <el-input
          v-model="queryParams.productCode"
          placeholder="请输入商品编码"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button class="blue-btn" icon="Search" @click="handleQuery"
          >搜索</el-button
        >
        <el-button class="gray-btn" icon="Refresh" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <el-table
      ref="singleTable"
      v-loading="loading"
      :data="productInfoList"
      @select="selectChange"
    >
      <el-table-column type="selection" width="40" />
      <el-table-column label="序号" width="50">
        <template #default="scope">
          {{
            queryParams.pageSize * (queryParams.pageNum - 1) + scope.$index + 1
          }}
        </template>
      </el-table-column>
      <el-table-column
        label="商品名称"
       
        prop="productName"
        width="80"
      />
      <el-table-column
        label="商品编码"
       
        prop="productCode"
        width="80"
      />
      <el-table-column label="可用库存" prop="availableCount">
        <template #default="scope">
          {{ scope.row.availableWeight }}
          <dict-tag :options="capacity_unit" :value="scope.row.weightUnit" />
          <span>/</span>
          {{ scope.row.availableCount }}
          <dict-tag :options="capacity_unit" :value="scope.row.countUnit" />
        </template>
      </el-table-column>
      <el-table-column label="期货仓单库存" prop="futureCount">
        <template #default="scope">
          {{ scope.row.futureWeight }}
          <dict-tag :options="capacity_unit" :value="scope.row.weightUnit" />
          <span>/</span>
          {{ scope.row.futureCount }}
          <dict-tag :options="capacity_unit" :value="scope.row.countUnit" />
        </template>
      </el-table-column>
      <el-table-column label="现货仓单库存" prop="spotCount">
        <template #default="scope">
          {{ scope.row.spotWeight }}
          <dict-tag :options="capacity_unit" :value="scope.row.weightUnit" />
          <span>/</span>
          {{ scope.row.spotCount }}
          <dict-tag :options="capacity_unit" :value="scope.row.countUnit" />
        </template>
      </el-table-column>
      <el-table-column label="总库存" prop="remainCount">
        <template #default="scope">
          {{ scope.row.remainWeight }}
          <dict-tag :options="capacity_unit" :value="scope.row.weightUnit" />
          <span>/</span>
          {{ scope.row.remainCount }}
          <dict-tag :options="capacity_unit" :value="scope.row.countUnit" />
        </template>
      </el-table-column>
      <el-table-column label="规格" prop="specificationVal">
        <template #default="scope">
          {{ scope.row.specificationVal }}
          <dict-tag :options="capacity_unit" :value="scope.row.weightUnit" />
          <span>/1</span>
          <dict-tag :options="capacity_unit" :value="scope.row.countUnit" />
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      v-model:page="queryParams.pageNum"
      v-model:limit="queryParams.pageSize"
      @pagination="getList"
    />
    <template #footer>
      <div class="dialog-footer">
        <el-button class="gray-btn" @click="cancel">取 消</el-button>
        <el-button class="blue-btn" @click="submitForm">确 定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive, onMounted } from "vue"
import { getStockSumY } from "@/api/wms/components/index"
const { proxy } = getCurrentInstance()
const store = useStore()
const emits = defineEmits(["update:modelValue", "submitForm"])
const props = defineProps({
  // 数据
  modelValue: {
    type: Boolean,
    default: false,
  },
  companyCode: {
    type: String,
    default: null,
  },
  whName: {
    type: String,
    default: null,
  },
})
const { capacity_unit } = proxy.useDict("capacity_unit")
const data = reactive({
  total: 0,
  queryParams: {
    pageNum: 1,
    pageSize: 10,
    productName: null,
    productCode: null,
    companyCode: null, //客户id
    whCode: null, //仓库id
    varietyCode: store.state.variety.varietyId,
  },
  loading: false,
  productInfoList: [],
  selected: null,
})
const { total, queryParams, loading, productInfoList, selected } = toRefs(data)
watch(
  () => props.whName,
  (value) => {
    if (props.whName && props.companyCode) {
      queryParams.value.companyCode = props.companyCode
      queryParams.value.whCode = props.whName.split(",")[1]
      getList()
    }
  },
  {
    immediate: true,
  }
)
watch(
  () => props.companyCode,
  (value) => {
    if (props.whName && props.companyCode) {
      queryParams.value.companyCode = props.companyCode
      queryParams.value.whCode = props.whName.split(",")[1]
      getList()
    }
  },
  {
    immediate: true,
  }
)
watch(
  () => store.state.variety.varietyId,
  (value) => {
    if (value) {
      queryParams.value.varietyCode = value
      getList()
    }
  },
  {
    immediate: true,
  }
)
/** 搜索按钮操作 */
function handleQuery() {
  queryParams.value.pageNum = 1
  getList()
}
/** 重置按钮操作 */
function resetQuery() {
  proxy.resetForm("queryRef")
  handleQuery()
}

/** 查询列表 */
function getList() {
  loading.value = true
  // 入库商品
  getStockSumY(queryParams.value).then((response) => {
    productInfoList.value = response.rows
    total.value = response.total
    loading.value = false
  })
}
// 多选框选中数据
function selectChange(selection) {
  if (selection.length > 1) {
    const del_row = selection.shift()
    proxy.$refs.singleTable.toggleRowSelection(del_row, false)
  }
  selected.value = selection[0]
}

// 取消按钮
function cancel() {
  emits("update:modelValue", false)
  proxy.$refs.singleTable.clearSelection()
  selected.value = null
}

/** 提交按钮 */
function submitForm() {
  if (selected.value) {
    emits("submitForm", selected.value)
    cancel()
  } else {
    proxy.$modal.msgError("请选择客户")
  }
}
// onMounted(() => {
//   handleQuery();
// });
</script>

<style scoped>
:deep(th .el-checkbox) {
  display: none;
}
</style>

<template>
  <el-cascader v-model="data" ref="rf" :props="stackSelectProps" :multiple="multiple" :varietyCode="varietyCode"
    :minCount="minCount" :minWeight="minWeight" :placeholder="placeholder" :needCheck="needCheck" @change="handleChange"
    :options="value" filterable :filter-method="fileterMethor" :clearable="clearable" />
</template>

<script setup>
import { isArray } from '@/utils/validate'
import { listRoom } from "@/api/wms/house/room"
import { listStack } from "@/api/wms/house/stack"

//const warehouse = JSON.parse(localStorage.getItem('warehouse')) || '';
const props = defineProps({
  /* 最小剩余重量 */
  minWeight: {
    type: Number,
    required: false,
    default: () => {
      return 0
    }
  },
  /* 最小剩余数量 */
  minCount: {
    type: Number,
    required: false,
    default: () => {
      return 0
    }
  },
  /* 种类编码 */
  varietyCode: {
    type: String,
    required: false,
    default: () => {
      return ''
    }
  },
  /**输入框内部的文字 */
  placeholder: {
    type: String,
    default: ''
  },

  // stackIds: {
  //   type: Number,
  //   default: () => 0
  // },

  multiple: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  needCheck: {
    type: Boolean,
    default: () => {
      return true
    }
  },
  modelValue: {
    type: Object,
    default: null
  },
  clearable: {
    type: Boolean,
    default: () => {
      return false
    }
  }
})

const emit = defineEmits(['change', 'update:modelValue'])
const { modelValue, multiple, needCheck } = props
function fileterMethor(node, keyword) {
  console.log(node, keyword)
  return true
}

const idMap = ['a', 'r', 's']
let value = []
if (modelValue !== null && isArray(modelValue)) {
  if (multiple) {
    modelValue.forEach((item) => {
      value.push(item.map((d) => d + idMap[idx]))
    })
  } else {
    modelValue.forEach((item, idx) => {
      value.push(item + idMap[idx])
    })
  }
}

const data = ref(value)

const rf = ref()
function handleChange(value) {
  let originValue = []
  if (value) {
    if (multiple) {
      value.forEach((item) => {
        originValue.push(item.map((d) => Number(d)))
      })
    } else {
      value.forEach((item) => {
        originValue.push(Number(item))
      })
    }
  }
  console.log(originValue)
  emit('update:modelValue', originValue)

  const labelValue = []
  rf.value.getCheckedNodes().forEach((item) => {
    if (item.isLeaf) {
      labelValue.push(item.text)
    }
  })
  emit('change', value, labelValue)
}

const stackSelectProps = {
  lazy: true,
  multiple: props.multiple,
  separator: '/',
  lazyLoad(node, resolve) {
    const { level } = node
    if (level === 0) {
      listRoom().then((res) => {
        const nodes = res.rows.map((item) => {
          return {
            label: item.roomName,
            value: item.id,
            level: 0,
            leaf: false,
          }
        })
        console.log(nodes)
        resolve(nodes)
      })
    } else if (level === 1) {
      console.log(node)
      const params = {
        roomId: node.value
      }
      listStack(params).then((res) => {
        const nodes = res.rows.map((item) => {
          return {
            label: item.stackName,
            value: item.stackId,
            level: 1,
            leaf: true
          }
        })
        resolve(nodes)
      })
    }
  }
}
watch(() => props.modelValue,
  (val) => {
    if (Object.keys(val).length === 0) {
      data.value = []
    }
  })
</script>
<template>
  <el-popover ref="popoverRef" v-if="props.files" placement="left" :width="fileWidth" trigger="click"
    @before-enter="showImagesUrl(props.files)">
    <template #reference>
      <el-button type="text">查看</el-button>
    </template>
    <div class="file-box">
      <div class="file-box-item" v-for="file in fileList" :key="file.url">
        <el-link v-if="file.type === 'pdf' || file.type === 'PDF'" :href="file.url" target="_blank">
          <el-image :src="pdfImage" fit="cover" class="file" />
        </el-link>
        <el-link v-else-if="file.type === 'rar' || file.type === 'zip'" :href="file.url" target="_blank">
          <el-image :src="rarImage" fit="cover" class="file" />
        </el-link>
        <el-image v-else :src="file.url" :preview-teleported="true" :preview-src-list="images" fit="cover" class="file" />
      </div>
    </div>
  </el-popover>
</template>
<script setup name="ShowFiles">
import { getFileUrl } from "@/api/wms/file"
import { computed, watch, ref, onMounted } from "vue"
import pdfImage from '@/assets/images/pdf.png'
import rarImage from '@/assets/images/rar.png'
const { proxy } = getCurrentInstance()
const emits = defineEmits(['getFile'])
const props = defineProps({
  files: {
    type: [String],
    default: ''
  }
})
const popoverRef = ref()
const fileWidth = ref(240)
const fileList = ref([])
const images = computed(() => {
  return fileList.value.filter(item => item.type !== 'pdf' && item.type !== 'PDF' && item.type !== 'rar' && item.type !== 'zip').map(item => {
    return item.url
  }) || []
})

async function showImagesUrl(files) {
  if (files) {
    fileList.value = []
    const arr = files.split(',').filter(item => item !== '')
    const res = arr.map(async (item) => {
      const type = item.split('.')[1]
      const urls = await getImagesUrl(item)
      return { type, url: urls.join() }
    })
    fileList.value = await Promise.all(res)
  } else {
    fileList.value = []
  }

}


// 批量获取图片路径
async function getImagesUrl(pic) {
  let urls = []
  if (
    pic &&
    pic.split(",").length > 0
  ) {
    urls = pic.split(",")
    return await Promise.all(
      urls.map(async (it) => {
        const res = await getFileUrl(it)
        return res.data
      })
    )
  }
}
watch(
  () => fileList.value,
  (val) => {
    if (val.length >= 1 && val.length <= 10) {
      fileWidth.value = val.length * 77
    } else if (val.length > 10) {
      fileWidth.value = 770
    }
  },
  { deep: true, immediate: true }
)
</script>
<style scoped lang="scss">
.file-box {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;

  &-item {
    .file {
      width: 60px;
      height: 60px;
      padding: 5px;
      border: 1px solid rgba(0, 0, 0, .08);
      border-radius: 4px;
      box-sizing: border-box;
    }
  }
}
</style>
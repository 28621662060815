import request from '@/utils/request'

// 查询出库单列表
export function listOutOrder(query) {
  return request({
    url: '/wms/outOrder/list',
    method: 'get',
    params: query
  })
}

// 查询出库单详细
export function getOutOrder(id) {
  return request({
    url: '/wms/outOrder/' + id,
    method: 'get'
  })
}

// 提交出库单
export function submitOutOrder(data) {
  return request({
    url: '/wms/outOrder/submit',
    method: 'post',
    data: data
  })
}

//获取出库作业列表
export function getOutJobList(query) {
  return request({
    url: '/wms/outOrder/jobList',
    method: 'get',
    params: query
  })
}


// 获取作业详细信息
export function getJobInfo(id) {
  return request({
    url: '/wms/outOrder/job/' + id,
    method: 'get'
  })
}

// 更新作业信息
export function updateJob(data) {
  return request({
    url: '/wms/outOrder/jobEdit/',
    method: 'put',
    data: data
  })
}


// 修改出库单
export function editOutOrder(data) {
  return request({
    url: '/wms/outOrder/edit',
    method: 'put',
    data: data
  })
}


// 接受出库单
export function acceptOutOrderOperation(id) {
  return request({
    url: '/wms/outOrder/operation/accept/' + id,
    method: 'put'
  })
}
// 完成出库单
export function completeOutOrderOperation(id) {
  return request({
    url: '/wms/outOrder/operation/complete/' + id,
    method: 'put'
  })
}


// 审核出库单
export function auditOutOrder(data) {
  return request({
    url: '/wms/outOrder/audit',
    method: 'post',
    data: data
  })
}

// 删除出库单
export function delOutOrder(id) {
  return request({
    url: '/wms/outOrder/' + id,
    method: 'delete'
  })
}


export function getAvailableTeam(query) {
  return request({
    url: '/wms/outOrder/getAvailableTeam',
    method: 'get',
    params: query
  })
}



// 获取叉车称重详细信息
export function getJobCarDetail(id) {
  return request({
    url: '/wms/outOrder/jobCarDetail/' + id,
    method: 'get'
  })
}

// 查询入库单详细
export function getOutThingsInfo(id) {
  return request({
    url: '/wms/outOrder/things/' + id,
    method: 'get'
  })
}


//车辆照片抓拍
export function getCarPicture(data) {
  return request({
    url: '/wms/outOrder/carPic',
    method: 'post',
    data: data
  })
}

//批量确认出库单
export function batchAudit(id) {
  return request({
    url: '/wms/outOrder/batchAudit/' + id,
    method: 'put'
  })
}

//通过预约单号查询涉及云仓单列表
export function getCloudCodeByAppCode(query) {
  return request({
    url: '/wms/outOrder/getCloudCodeByAppCode',
    method: 'get',
    params: query
  })
}
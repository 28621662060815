
import request from '@/utils/request'

// 新增仓单注册信息
export function receiptRegisterAdd(data) {
  return request({
    url: '/wms/receiptRegister/add',
    method: "post",
    data
  });
}
// 新增仓单注册信息(新)
export function receiptRegisterAddNew(data) {
  return request({
    url: '/wms/receiptRegister/addnew',
    method: "post",
    data
  });
}
// 仓单注册审核
export function receiptRegisterCheck(data) {
  return request({
    url: '/wms/receiptRegister/check',
    method: "post",
    data
  });
}
// 仓单链审核
export function cloudCheck(data) {
  return request({
    url: '/wms/receiptRegister/lotCheck',
    method: "post",
    data
  });
}
// 仓单注册撤销
export function receiptRegisterUndo(data) {
  return request({
    url: '/wms/receiptRegister/undo',
    method: "post",
    data
  });
}
// 仓单注册审核 多条数据审核
export function receiptRegisterCheckMore(data) {
  return request({
    url: '/wms/receiptRegister/checkBatch',
    method: "post",
    data
  });
}
// 修改仓单注册信息
export function receiptRegisterEdit(data) {
  return request({
    url: '/wms/receiptRegister/edit',
    method: "post",
    data
  });
}
// 修改仓单注册信息（新）
export function receiptRegisterEditNew(data) {
  return request({
    url: '/wms/receiptRegister/editnew',
    method: "post",
    data
  });
}
// 修改仓单注册信息支持修改垛位
export function receiptRegisterUpdateNew(data) {
  return request({
    url: '/wms/receiptRegister/updateNew',
    method: "post",
    data
  });
}
// 修改已审核期货仓单到新垛位（期货移库）
export function receiptRegisterUpdateStack(data) {
  return request({
    url: '/wms/receiptRegister/updateStack',
    method: "post",
    data
  });
}
// 根据客户获取所有现货商品信息
export function receiptRegisterProductList(params) {
  return request({
    url: '/wms/receiptRegister/productList',
    method: "get",
    params
  });
}
// 根据客户获取商品列表
export function productListByCompanyId(params) {
  return request({
    url: '/wms/receiptRegister/productListByCompanyId',
    method: "get",
    params
  });
}
// 选择现货仓单搜索，返回现货垛位、商品以及库存的列表
export function stackProductList(params) {
  return request({
    url: '/wms/receiptRegister/stackProductList',
    method: "get",
    params
  });
}
// 通过客户，商品，垛位数组，查询垛位库存列表
export function stackProductCompanyCodeList(params) {
  return request({
    url: '/wms/receiptRegister/stackProductCompanyCodeList',
    method: "get",
    params
  });
}
// 查询仓单注册信息列表
export function receiptRegisterList(params) {
  return request({
    url: '/wms/receiptRegister/list',
    method: "get",
    params
  });
}
// 查询仓单注册信息列表（新）
export function receiptRegisterListNew(params) {
  return request({
    url: '/wms/receiptRegister/listnew',
    method: "get",
    params
  });
}

// 查询已注册的期货仓单详情
export function receiptCancelById(id) {
  return request({
    url: '/wms/receiptRegister/register/info/' + id,
    method: 'get'
  })
}


// 删除仓单注册信息
export function receiptDeleteById(id) {
  return request({
    url: '/wms/receiptRegister/' + id,
    method: 'delete'
  })
}


// 查询已注册的期货仓单列表
export function receiptRegisterRegisterList(params) {
  return request({
    url: '/wms/receiptRegister/register/list',
    method: "get",
    params
  });
}
// 查询已注册的期货仓单列表（新）
export function receiptRegisterRegisterListNew(params) {
  return request({
    url: '/wms/receiptRegister/register/listnew',
    method: "get",
    params
  });
}
// 获取期货仓单列表(批量)
export function getReceiptListBatch(data) {
  return request({
    url: '/wms/receiptRegister/infos/' + data,
    method: "get",
  });
}
// 获取仓单注册信息详细信息
export function receiptRegisterById(id) {
  return request({
    url: '/wms/receiptRegister/' + id,
    method: 'get'
  })
}
// 获取仓单注册信息详细信息(新)
export function receiptRegisterByIdNew(id) {
  return request({
    url: '/wms/receiptRegister/info/' + id,
    method: 'get'
  })
}
// 查询详情接口
export function getInfoByCloudRoute(id) {
  return request({
    url: '/wms/receiptRegister/getInfoByCloudRoute/' + id,
    method: 'get'
  })
}
// 查询详情接口
export function receiptCancelByCloudRoute(id) {
  return request({
    url: '/wms/receiptCancel/cloudRoute/' + id,
    method: 'get'
  })
}
// 查询融资详情接口
export function showReceipt(id) {
  return request({
    url: '/wms/receiptRegister/showReceipt/' + id,
    method: 'get'
  })
}
// 融资详情编辑 买卖合同 saleContractFiles 委托函 delegateLetterFiles 保管合同 storageContractFiles
export function editReceipt(data) {
  return request({
    url: '/wms/receiptRegister/editReceipt',
    method: 'post',
    data
  })
}


import {
  listOrder
} from "@/api/wms/order/outOrder";

export default {
  state: {
    out_rangeTime: [],
    out_goodsCode: '',
    out_whCode: '',
    out_whName: '',
    out_companyCode: '',
    out_companyName: '',
    out_productName: '',
    out_outState: '',
    out_loading: false,
    out_orderList: [],
    out_total: 0,
    out_pageNum: 1,
    out_pageSize: 10
  },
  // getters
  getters: {
    rangeTime: (state) => state.out_rangeTime,
    goodsCode: (state) => state.out_goodsCode,
    whCode: (state) => state.out_whCode,
    whName: (state) => state.out_whName,
    companyCode: (state) => state.out_companyCode,
    companyName: (state) => state.out_companyName,
    productName: (state) => state.out_productName,
    outState: (state) => state.out_outState,
    loading: (state) => state.out_loading,
    orderList: (state) => state.out_orderList,
    total: (state) => state.out_total,
    pageNum: (state) => state.out_pageNum,
    pageSize: (state) => state.out_pageSize
  },
  mutations: {
    SET_oTIME: (state, time) => {
      state.out_rangeTime = time
    },
    SET_oGOODSCODE: (state, code) => {
      state.out_goodsCode = code
    },
    SET_oWHCODE: (state, whCode) => {
      state.out_whCode = whCode
    },
    SET_oWHNAME: (state, whName) => {
      state.out_whName = whName
    },
    SET_COMPANYCODE: (state, companyCode) => {
      state.out_companyCode = companyCode
    },
    SET_oCOMPANYNAME: (state, companyName) => {
      state.out_companyName = companyName
    },
    SET_oOUTSTATE: (state, outState) => {
      state.out_outState = outState
    },
    SET_oLOADING: (state, loading) => {
      state.out_loading = loading
    },
    SET_oORDERLIST: (state, orderList) => {
      state.out_orderList = orderList
    },
    SET_oTOTAL: (state, out_total) => {
      state.out_total = out_total
    },
    SET_oPAGENUM: (state, pageNum) => {
      state.out_pageNum = pageNum
    },
    SET_oPAGESIZE: (state, orderList) => {
      state.out_pageSize = pageSize
    },
  },

  actions: {
    // 获取列表
    GetOutOrderList({
      commit,
      state
    }, params) {
      commit('SET_LOADING', true)
      return new Promise((resolve, reject) => {
        listOrder(params)
          .then((res) => {
            commit('SET_oTOTAL', res.total)
            commit('SET_oORDERLIST', res.rows)
            commit('SET_oLOADING', false)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })

    }
  },
  namespaced:true//默认值:false,. true:启用命名空间
}
import {
  listOrder
} from "@/api/wms/order/inOrder";

export default {
  state: {
    rangeTime: [],
    goodsCode: '',
    whCode: '',
    whName: '',
    companyCode: '',
    companyName: '',
    productName: '',
    inState: '',
    loading: false,
    orderList: [],
    total: 0,
    pageNum: 1,
    pageSize: 10
  },
  // getters
  getters: {
    inOrder_rangeTime: (state) => state.rangeTime,
    inOrder_goodsCode: (state) => state.goodsCode,
    inOrder_whCode: (state) => state.whCode,
    inOrder_whName: (state) => state.whName,
    inOrder_companyCode: (state) => state.companyCode,
    inOrder_companyName: (state) => state.companyName,
    inOrder_productName: (state) => state.productName,
    inOrder_inState: (state) => state.inState,
    inOrder_loading: (state) => state.loading,
    inOrder_orderList: (state) => state.orderList,
    inOrder_total: (state) => state.total,
    inOrder_pageNum: (state) => state.pageNum,
    inOrder_pageSize: (state) => state.pageSize
  },
  mutations: {
    SET_TIME: (state, time) => {
      state.rangeTime = time
    },
    SET_GOODSCODE: (state, code) => {
      state.goodsCode = code
    },
    SET_WHCODE: (state, whCode) => {
      state.whCode = whCode
    },
    SET_WHNAME: (state, whName) => {
      state.whName = whName
    },
    SET_COMPANYCODE: (state, companyCode) => {
      state.companyCode = companyCode
    },
    SET_COMPANYNAME: (state, companyName) => {
      state.companyName = companyName
    },
    SET_INSTATE: (state, inState) => {
      state.inState = inState
    },
    SET_LOADING: (state, loading) => {
      state.loading = loading
    },
    SET_PRODUCTNAME: (state, productName) => {
      state.productName = productName
    },
    SET_ORDERLIST: (state, orderList) => {
      state.orderList = orderList
    },
    SET_TOTAL: (state, total) => {
      state.total = total
    },
    SET_PAGENUM: (state, pageNum) => {
      state.pageNum = pageNum
    },
    SET_PAGESIZE: (state, orderList) => {
      state.pageSize = pageSize
    },
  },

  actions: {
    // 获取列表
    GetOrderList({
      commit,
      state
    }, params) {
      commit('SET_LOADING', true)
      return new Promise((resolve, reject) => {
        listOrder(params)
          .then((res) => {
            console.log(res)
            commit('SET_TOTAL', res.total)
            commit('SET_ORDERLIST', res.rows)
            commit('SET_LOADING', false)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })

    }
  },
  namespaced:true//默认值:false,. true:启用命名空间
}
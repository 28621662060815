import request from '@/utils/request'

// 查询磅单记录列表
export function listWeighbridge(query) {
  return request({
    url: '/wms/weighbridge/list',
    method: 'get',
    params: query
  })
}

// 查询磅单记录详细
export function getWeighbridge(id) {
  return request({
    url: '/wms/weighbridge/' + id,
    method: 'get'
  })
}

// 新增磅单记录
export function addWeighbridge(data) {
  return request({
    url: '/wms/weighbridge',
    method: 'post',
    data: data
  })
}

// 新增磅单记录（新接口）
export function addWeighbridgeNew(data) {
  return request({
    url: '/wms/weighbridge/addNew',
    method: 'post',
    data: data
  })
}

// 修改磅单记录
export function updateWeighbridge(data) {
  // return request({
  //   url: '/wms/weighbridge',
  //   method: 'put',
  //   data: data
  // })
  return request({
    url: '/wms/weighbridge/edit',
    method: 'post',
    data: data
  })
}

// 删除磅单记录
export function delWeighbridge(id) {
  return request({
    url: '/wms/weighbridge/' + id,
    method: 'delete'
  })
}

// 红冲
export function weighbridgeRedFlushApi(data) {
  return request({
    url: '/wms/weighbridge/redFlush',
    method: 'post',
    data
  })
}

// 磅单数量、重量统计
export function weighbridgeStatistics(query) {
  return request({
    url: '/wms/weighbridge/statistics',
    method: 'get',
    params: query
  })
}


// 查询列表
export function weighbridgeOwnList(query) {
  return request({
    url: '/wms/weighbridge/ownList',
    method: 'get',
    params: query
  })
}
// 查询磅单详细列表
export function pageStatisticsList(query) {
  return request({
    url: '/wms/weighbridge/pageStatisticsList',
    method: 'get',
    params: query
  })
}
// 拆分磅单
export function bachSave(data) {
  return request({
    url: '/wms/weighbridge/bachSave',
    method: 'post',
    data
  })
}

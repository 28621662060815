import { getFileKey } from '@/components/FileUpload/util'
import request from '@/utils/request'

export const fileSetting = {
  accessid: '',
  policy: '',
  signature: '',
  dir: '',
  callback: '',
  host: '',
  expire: 0
}

export async function getSignature() {
  if (fileSetting.expire > Date.now() / 1000 + 3) {
    return { data: fileSetting }
  }
  console.log(fileSetting)
  const data = await request({
    url: '/file/oss/getSignature',
    method: 'GET',
    headers: {
      repeatSubmit: false
    }
  })

  fileSetting.expire = data.expire
  fileSetting.accessid = data.accessid
  fileSetting.policy = data.policy
  fileSetting.dir = data.dir
  fileSetting.callback = data.callback
  fileSetting.host = data.host
  fileSetting.signature = data.signature

  return {
    data: fileSetting
  }
}

/**
 * @param {string} tmpPath
 * @param {'inline'|'attachment'} disposition
 */
export async function getFileUrl(tmpPath) {
  return await request({
    url: `/file/minio/downloadFile?filePath=${tmpPath}`,
    method: 'get',
  })
}

export async function downloadMultipartFile(files) {
  return await request({
    url: `/file/minio/downloadMultipartFile`,
    method: 'post',
    data: {
      files
    }
  })
}

export async function getFilePath(tmpPath, disposition = 'inline') {
  return await request({
    url: '/file/oss/getDownUrl',
    method: 'POST',
    headers: {
      repeatSubmit: false
    },
    data: {
      fileName: tmpPath,
      disposition: disposition
    }
  })
}

export async function uploadFile(e, host) {
  const formData = new FormData()

  const { OSSAccessKeyId, policy, signature, callback } = e.data
  const fileName = e.file.name
  const key = getFileKey(fileName)
  formData.append('OSSAccessKeyId', OSSAccessKeyId)
  formData.append('policy', policy)
  formData.append('signature', signature)
  formData.append('callback', callback)
  formData.append('key', key)
  formData.append('file', e.file)

  await request({
    url: host,
    method: 'POST',
    headers: {
      repeatSubmit: false
    },
    data: formData
  })

  return {
    data: {
      ossUrl: '',
      url: key,
      name: fileName
    }
  }
}

<template>
  <div :class="{ 'has-logo': showLogo }" :style="{
    backgroundColor:
      sideTheme === 'theme-dark'
        ? variables.menuBackground
        : variables.menuLightBackground,
  }">
    <div class="level">
      <div>{{ title }}</div>
      <div :class="[isCollapse ? 'hide' : '']" @click="toggleSideBar">
        <svg-icon v-if="!isCollapse" icon-class="retract"></svg-icon>
        <svg-icon v-else icon-class="retract" style=" transform: rotate(180deg);"></svg-icon>
      </div>
    </div>
    <el-scrollbar :class="sideTheme" wrap-class="scrollbar-wrapper" style="
        --el-scrollbar-bg-color: transparent;
        --el-scrollbar-hover-bg-color: transparent;
      ">
      <el-menu :default-openeds="defaultOpeneds" :collapse="isCollapse" :background-color="sideTheme === 'theme-dark'
        ? variables.menuBackground
        : variables.menuLightBackground
        " :text-color="sideTheme === 'theme-dark'
    ? variables.menuColor
    : variables.menuLightColor
    " :unique-opened="true" :active-text-color="theme" :collapse-transition="false" mode="vertical"
        style="--el-font-size-base: 14px">
        <sidebar-item v-for="(route, index) in menuDataUpdate" :index="index" :key="route.path + index" :item="route"
          :base-path="route.fullPath" :activeName="activeName" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script setup>
import SidebarItem from "./SidebarItem"
import variables from "@/assets/styles/variables.module.scss"
import { computed } from "vue"
const route = useRoute()
const store = useStore()
const sidebarRouters = computed(() => store.getters.sidebarRouters.filter((item) => !item.hidden))
const showLogo = computed(() => store.state.settings.sidebarLogo)
const sideTheme = computed(() => store.state.settings.sideTheme)
const theme = computed(() => store.state.settings.theme)
const isCollapse = computed(() => !store.state.app.sidebar.opened)
const activeMenu = computed(() => {
  const { meta, path } = route
  if (meta.activeMenu) {
    return meta.activeMenu
  }
  return path
})
const props = defineProps({
  // 数据
  menuDataUpdate: {
    type: Object,
    default: function () {
      return {}
    },
  },
  activeName: {
    type: Number,
    default: 0,
  },
})
const defaultOpeneds = ref([localStorage.getItem('openKey')])

const title = computed(() => {
  if (sidebarRouters.value[props.activeName]) {
    return sidebarRouters.value[props.activeName].meta.title
  } else {
    return sidebarRouters.value[0].meta.title
  }
})
function toggleSideBar() {
  store.dispatch("app/toggleSideBar")
}
</script>

<style lang="scss" scoped>
.level {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 19px 12px;
  // font-size: 18px;
  font-size: 14px;
  font-weight: 650;
  color: rgba(0, 0, 0, .85);
  background-color: #fff;

  .svg-icon {
    font-size: 16px;
    margin-right: 0 !important;
    color: rgba(0, 0, 0, .85);
  }
}
</style>

import { createStore } from 'vuex'
import app from './modules/app'
import user from './modules/user'
import tagsView from './modules/tagsView'
import permission from './modules/permission'
import settings from './modules/settings'
import getters from './getters'
import variety from './modules/variety'
import inOrder from './modules/inOrder'
import outOrder from './modules/outOrder'

const store = createStore({
  modules: {
    app,
    user,
    tagsView,
    permission,
    settings,
    variety,
    inOrder,
    outOrder
  },
  getters
})

export default store

<template>
  <div class="card">
    <div class="title">{{ title }}</div>
    <div class="card-body">
      <div class="video-container">
        <template v-if="videos.length > 0">
          <div class="video-wrapper">
            <Camera ref="cameraRef" :auto-play="false" :iWidth="iWidth" :iHeight="iHeight"></Camera>
          </div>
          <div
            class="left-control"
            v-if="currentIdx > 0"
            @click="previous"
          >
            <el-icon><ArrowLeftBold /> </el-icon>
          </div>
          <div
            class="right-control"
            v-if="currentIdx < videos.length - 1"
            @click="next"
          >
            <el-icon><ArrowRightBold /></el-icon>
          </div>
        </template>
        <el-empty v-else :image-size="100" description="暂无视频"></el-empty>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { ArrowLeftBold, ArrowRightBold } from '@element-plus/icons-vue'
import Camera from '@/components/Camera/VideoPlayback.vue'

const props = defineProps({
  videos: {
    type: Array,
    default: () => []
  },
  title: {
    type: String,
    default: '监控视频'
  },
  iWidth: {
    type: Number,
    default: 208
  },
  iHeight: {
    type: Number,
    default: 238
  }
})

const currentIdx = ref(0)
const cameraRef = ref()
function previous() {
  currentIdx.value--
  play()
}

function next() {
  currentIdx.value++
  play()
}

function play() {
  if (cameraRef.value) {
    cameraRef.value.play(props.videos[currentIdx.value])
  }
}

watch(
  () => props.videos,
  (value) => {
    if (value.length > 0) {
      currentIdx.value = 0
      play()
    }
  }
)

onMounted(() => {
  if (props.videos.length > 0) {
    play()
  }
})
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  width: 100%;
  height: 100%;
  .title {
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    width: 100%;
    height: 38px;
    box-sizing: border-box;
    padding: 0 0 0 10px;
    line-height: 38px;
    border-radius: 8px 8px 0 0;
    z-index: 1;
  }
  .card-body {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    .video-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .video-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
      }

      .left-control {
        display: none;
        position: absolute;
        left: 0;
        top: 50%;
        cursor: pointer;
        transform: translateY(-50%);
        font-size: 20px;
      }

      .right-control {
        display: none;
        position: absolute;
        right: 0;
        top: 50%;
        cursor: pointer;
        transform: translateY(-50%);
        font-size: 20px;
      }

      &:hover {
        .left-control,
        .right-control {
          display: block;
        }
      }
    }
  }
}
</style>

<template>
  <div
    :class="$style.video"
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.3)"
  >
    <div :id="cameraId" :class="$style['video-body']"></div>
    <el-icon :class="$style['play-btn']" v-if="showPlayIcon" @click="playVideo"
      ><VideoPlay></VideoPlay
    ></el-icon>
  </div>
</template>

<script setup>
import { getCameraId, getQuery, MSE_IS_SUPPORT } from './util'
import moment from 'moment'
import { onBeforeUnmount, ref, onMounted } from 'vue'
import { VideoPlay } from '@element-plus/icons-vue'

const props = defineProps({
  wss: {
    type: String,
    default: ''
  },
  autoPlay: {
    type: Boolean,
    default: true
  },
  iWidth: {
    type: Number,
    default: 208
  },
  iHeight: {
    type: Number,
    default: 238
  }
})

const emit = defineEmits(['video-play-end'])

const cameraId = getCameraId()

let player
let wss = props.wss

const isPlaying = ref(false)

const loading = ref(false)

const showPlayIcon = ref(!props.autoPlay)

function createPlayer() {
  player = new window.JSPlugin({
    szId: cameraId,
    szBasePath: '/static/js/h5player/',
    iMaxSplit: 1,
    isCurrentSplit: 1,
    iWidth: props.iWidth,
    iHeight: props.iHeight,
    oStyle: {
      borderSelect: 'transparent'
    }
  })
  player.JS_SetWindowControlCallback({
    StreamEnd: () => {
      isPlaying.value = false
      showPlayIcon.value = true
      emit('video-play-end')
    }
  })
}

/**
 * @param {string} url
 */
function play(url) {
  showPlayIcon.value = false
  wss = url

  if (!player) {
    createPlayer()
  }

  stop()
  loading.value = true
  const query = getQuery(url)
  if (query.beginTime && query.endTime) {
    const beginTime =
      moment(query.beginTime, 'YYYYMMDDTHHmmss').format('YYYY-MM-DDTHH:mm:ss') +
      'Z'
    const endTime =
      moment(query.endTime, 'YYYYMMDDTHHmmss').format('YYYY-MM-DDTHH:mm:ss') +
      'Z'
    player
      .JS_Play(
        url,
        { playURL: url, mode: MSE_IS_SUPPORT ? 0 : 1 },
        0,
        beginTime,
        endTime
      )
      .then(() => {
        isPlaying.value = true
        loading.value = false
        intellectTrigger(true)
      })
  } else {
    player
      .JS_Play(url, { playURL: url, mode: MSE_IS_SUPPORT ? 0 : 1 }, 0)
      .then(() => {
        isPlaying.value = true
        loading.value = false
        intellectTrigger(true)
      })
  }
}

function stop() {
  loading.value = false
  if (player) {
    player.JS_Stop(0).then(() => {
      isPlaying.value = false
    })
  }
}

function intellectTrigger(openFlag) {
  if (!player) return
  const result = player.JS_RenderALLPrivateData(0, openFlag)
  // console.log(
  //   `${openFlag ? 'open' : 'close'} intellect ${
  //     result === 1 ? 'success' : 'failed'
  //   }`
  // )
}

function resize() {
  if (!player) return
  player.JS_Resize().then(
    () => {},
    (err) => {
      // console.log('JS_Resize failed')
    }
  )
}

function playVideo() {
  if (wss) {
    play(wss)
  }
}

onMounted(() => {
  if (props.autoPlay && props.wss) {
    play(props.wss)
  }
})

onBeforeUnmount(() => {
  stop()
  player = null
})

defineExpose({
  play,
  stop,
  resize
})
</script>

<style lang="scss" module>
.video {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;
  .video-body {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .play-btn {
    position: absolute;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    width: 50px;
    height: 50px;
    font-size: 50px;
    color: white;
  }
}
</style>

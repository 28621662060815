<template>
  <el-tooltip
    :content="formatterText(text)"
    raw-content
  >
  <div style=" width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{ text }}</div>
  </el-tooltip>
</template>
<script setup name="CustomTooltip">
const props = defineProps({
  text: {
    type: String,
    default: ''
  }
})
// 格式化
const formatterText = (text) => {
  let str = ''
  if(text) {
    if(text.includes(',')) {
      text.split(',').forEach((item, index) => {
        if(index > 0 && index % 2 === 0 )
        {
          str += ' <br />'
        }
        if(index % 2 !== 0) {
          str += ', '
        }
        str += `${item}`
      })
      return str
    } else {
      for(let i = 0; i< text.length; i++) {
        if(i > 0 && i % 30 === 0 ) {
          str += ' <br />'
        }
        str += `${text[i]}`
      }
    }
    return str
  }
}
</script>

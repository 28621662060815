import request from '@/utils/request'

// 查询品种管理列表
export function listVariety(query) {
  return request({
    url: '/wms/variety/list',
    method: 'get',
    params: query,
  })
}
//品种列表
export function pagelistVariety(query) {
  return request({
    url: '/wms/variety/pagelist',
    method: 'get',
    params: query,
  })
}
//仓库品种列表
export function whpagelistVariety(query) {
  return request({
    url: '/wms/variety/whpagelist',
    method: 'get',
    params: query,
  })
}
// 查询品种管理详细
export function getVariety(id) {
  return request({
    url: '/wms/variety/' + id,
    method: 'get',
  })
}

// 新增品种管理
export function addVariety(data) {
  return request({
    url: '/wms/variety',
    method: 'post',
    data: data,
  })
}

// 修改品种管理
export function updateVariety(data) {
  return request({
    url: '/wms/variety',
    method: 'put',
    data: data,
  })
}
// 修改仓库品种管理
export function updateWhVariety(data) {
  return request({
    url: '/wms/variety/whedit',
    method: 'post',
    data: data,
  })
}
// 删除品种管理
export function delVariety(id) {
  return request({
    url: '/wms/variety/' + id,
    method: 'delete',
  })
}

<template>
    <el-table :data="tableData" :border="border">
        <el-table-column label="磅单编号" width="160" prop="wbCode" fixed="left" />
        <el-table-column label="毛重" prop="grossWeight" />
        <el-table-column label="皮重" prop="tareWeight" />
        <el-table-column label="净重" prop="netWeight" />
        <el-table-column label="实际称重" prop="realWeight" />
        <el-table-column v-if="showCount" label="数量" prop="goodsCount">
            <template #default="scope">
                {{ scope.row.goodsCount }}
                <dict-tag :options="capacity_unit" :value="scope.row.countUnit" />
            </template>
        </el-table-column>
        <el-table-column label="车牌号" width="100" prop="carPlateNum" />
        <el-table-column label="司机姓名" width="130" show-overflow-tooltip prop="driverName" />
        <el-table-column label="司机手机号" prop="consignee" width="130" />
        <el-table-column label="司机身份证号" width="130" show-overflow-tooltip prop="driverIdCard" />
        <el-table-column v-if="scene === 'add'" label="操作" fixed="right" width="80">
            <template #default="scope">
                <el-button type="text" @click="removeRow(scope.$index)">移除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <div class="statistic">
        <div class="statistic-item">合计：</div>
        <div v-if="storageBasis === '2'" class="statistic-item">
            总重量：<span class="count">{{ totalWeight.toFixed(4) }}</span>
            <dict-tag :options="capacity_unit" :value="weightUnit" />
        </div>
        <div v-if="showCount" class="statistic-item">
            总数量：<span class="count">{{ totalCount.toFixed(4) }}</span>
            <dict-tag :options="capacity_unit" :value="countUnit" />
        </div>
        <div v-if="storageBasis === '1'" class="statistic-item">
            总重量：<span class="count">{{ totalWeight.toFixed(4) }}</span>
            <dict-tag :options="capacity_unit" :value="weightUnit" />
        </div>
    </div>
</template> 
<script setup name="StackList">
import { reactive } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const { proxy } = getCurrentInstance()
const { capacity_unit } = proxy.useDict('capacity_unit')
const props = defineProps({
    scene: {
        type: String,
        default: 'add'
    },
    data: {
        type: Array,
        default: () => {
            return []
        }
    },
    border: {
        type: Boolean,
        default: false
    },
    showCount: {
        type: Boolean,
        default: true
    }
})
const totalCount = ref(0)
const totalWeight = ref(0)
const storageBasis = ref()
const weightUnit = ref()
const countUnit = ref()
const tableData = ref([])
const removeRow = (index) => {
    tableData.value.splice(index, 1)
}
const summation = () => {
    totalCount.value = 0
    totalWeight.value = 0
    tableData.value.forEach(item => {
        totalCount.value += item?.goodsCount ? item.goodsCount * 1 : 0
        totalWeight.value += item?.realWeight ? item.realWeight * 1 : 0
    })
}

watch(
    () => tableData,
    (val) => {
        if (val.value.length > 0) {
            summation()
        }
    },
    { deep: true, immediate: true }
)

watch(
    () => props.data,
    (val) => {
        tableData.value = val
    },
    {
        immediate: true,
        deep: true
    }
)

watch(
    () => store.state.variety.itemObj,
    (itemObj) => {
        if (itemObj) {
            storageBasis.value = itemObj.storageBasis
            weightUnit.value = itemObj.goodsWeightUnit
            countUnit.value = itemObj.goodsCountUnit
        }
    },
    {
        immediate: true,
    }
)

defineExpose({
    tableData
})
</script>
<style lang="scss" scoped>
.statistic {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    width: 100%;
    padding: 0 20px;
    height: 56px;
    font-size: 16px;
    color: rgba(0, 0, 0, .85);
    font-weight: 650;
    //   box-shadow: 6px 6px 12px 6px rgba(0,0,0,0.08);
    background-color: #fff;

    &-item {
        .count {
            color: #337FFF;
            font-size: 18px;
        }
    }
}

:deep(.el-form-item__content) {
    margin-left: 0 !important;
}
</style>
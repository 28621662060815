import request from '@/utils/request'

// 查询在库库存列表
export function listStock(query) {
  return request({
    url: '/wms/stock/list',
    method: 'get',
    params: query
  })
}

// 查询在库库存详细
export function getStock(id) {
  return request({
    url: '/wms/stock/' + id,
    method: 'get'
  })
}

// 新增在库库存
export function addStock(data) {
  return request({
    url: '/wms/stock',
    method: 'post',
    data: data
  })
}

// 修改在库库存
export function updateStock(data) {
  return request({
    url: '/wms/stock',
    method: 'put',
    data: data
  })
}

// 删除在库库存
export function delStock(id) {
  return request({
    url: '/wms/stock/' + id,
    method: 'delete'
  })
}

// 查询在库垛位列表集合
export function listStockStack(query) {
  return request({
    url: '/wms/stockStack/list',
    method: 'get',
    params: query
  })
}

// 根据客户商品云仓单号汇总查询库存列表
export function selectByCompanyProductCloud(query) {
  return request({
    url: '/wms/stock/selectByCompanyProductCloud',
    method: 'get',
    params: query
  })
}
// 根据客户商品云仓单号汇总查询库存列表
export function selectByCompanyProductRoomStack(query) {
  return request({
    url: '/wms/stock/selectByCompanyProductRoomStack',
    method: 'get',
    params: query
  })
}
// 根据云仓单号查询客户、商品信息
export function selectCompanyProductByCloudCode(query) {
  return request({
    url: '/wms/stock/selectCompanyProductByCloudCode',
    method: 'get',
    params: query
  })
}

// 根据客户商品垛位汇总查询库存列表
export function selectByCompanyProductStack(query) {
  return request({
    url: '/wms/stock/selectByCompanyProductStack',
    method: 'get',
    params: query
  })
}
// 新垛位库存统计列表
export function selectByStackNew(query) {
  return request({
    url: '/wms/stock/selectByStack',
    method: 'get',
    params: query
  })
}
// 根据客户商品垛位汇总查询库存列表汇总
export function selectByCompanyProductStackSum(query) {
  return request({
    url: '/wms/stock/selectByCompanyProductStackSum',
    method: 'get',
    params: query
  })
}

// 根据客户商品垛位汇总查询库存列表汇总
export function selectStackStockRecord(query) {
  return request({
    url: '/wms/stock/selectStackStockRecord',
    method: 'get',
    params: query
  })
}

// 根据垛位云仓单汇总查询库存列表
export function selectByStackCloud(query) {
  return request({
    url: '/wms/stock/selectByStackCloud',
    method: 'get',
    params: query
  })
}
// 根据客户商品品类汇总查询垛位库存列表
export function selectByStackCloudNew(query) {
  return request({
    url: '/wms/stock/selectByStackCloudNew',
    method: 'get',
    params: query
  })
}
// 根据客户商品品类汇总查询垛位库存列表(期货注册编辑，传registerId)
export function selectByStackByRegister(query) {
  return request({
    url: '/wms/stock/selectByStackByRegister',
    method: 'get',
    params: query
  })
}
// 查询有期货库存的垛位
export function selectByStackFuture(query) {
  return request({
    url: '/wms/stock/selectByStackFuture',
    method: 'get',
    params: query
  })
}
// 出入库统计
export function selectInOutStockRecord(query) {
  return request({
    url: '/wms/stock/selectInOutStockRecord',
    method: 'get',
    params: query
  })
}

// 出入库统计汇总
export function selectInOutStockRecordSum(query) {
  return request({
    url: '/wms/stock/selectInOutStockRecordSum',
    method: 'get',
    params: query
  })
}

// 垛位库存明细查询接口
export function stackCloudDetailList(query) {
  return request({
    url: '/wms/stackCloudDetail/list',
    method: 'get',
    params: query
  })
}

/**
 * 
 * 
*/
// 查询当前仓库有库存的客户信息接口
export function selectCompanyByStock(query) {
  return request({
    url: '/wms/stock/selectCompanyByStock',
    method: 'get',
    params: query
  })
}

// 查询当前仓库有库存的商品信息接口
export function selectProductByStock(query) {
  return request({
    url: '/wms/stock/selectProductByStock',
    method: 'get',
    params: query
  })
}

// 查询当前仓库有库存的垛位信息接口
export function selectStackByStock(query) {
  return request({
    url: '/wms/stock/selectStackByStock',
    method: 'get',
    params: query
  })
}

/**
 * 查询云仓单接口
 * companyCode、productCode、stackId、cloudType(默认传参1,3)
*/
export function selectByCompanyProductStackCloud(params) {
  return request({
    url: '/wms/stock/selectByCompanyProductStackCloud',
    method: 'get',
    params: {
      cloudType: '1, 3',
      allowOperate: '1',
      ...params
    }
  })
}




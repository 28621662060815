<template>
  <el-dialog title="审核" :model-value="modelValue" width="500px" append-to-body @close="emits('cancelAudit')">
    <el-form :model="auditForm">
      <el-form-item label="审核结果">
        <!-- 操作类型,1.通过 2.驳回 3.撤销 -->
        <el-radio-group v-model="auditForm.handleState">
          <el-radio :label="1">通过</el-radio>
          <el-radio :label="2">驳回</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="审核意见">
        <el-input v-model="auditForm.auditRemark" :rows="2" type="textarea" placeholder="" maxlength="200" />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="submitAudit">确 定</el-button>
        <el-button @click="cancelAudit">取 消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive } from "vue"
const emits = defineEmits(["update:modelValue", "submitAudit", "cancelAudit"])
const props = defineProps({
  // 显示状态
  modelValue: {
    type: Boolean,
    default: false,
  },
})

const data = reactive({
  auditForm: {
    handleState: 1,
    auditRemark: "",
  },
})
const { auditForm } = toRefs(data)
/** 确定按钮操作 */
function submitAudit() {
  const data = ref({
    handleState: auditForm.value.handleState,
    auditRemark: auditForm.value.auditRemark
  })
  emits("submitAudit", data)
  setTimeout(() => {
    auditForm.value.handleState = 1
    auditForm.value.auditRemark = ""
  }, 200);
}
/** 取消按钮操作 */
function cancelAudit() {
  emits("cancelAudit")
  setTimeout(() => {
    auditForm.value.handleState = 1
    auditForm.value.auditRemark = ""
  }, 200);
}
</script>

import request from '@/utils/request'

// 查询客户车辆列表
export function listCompanyTruck(query) {
  return request({
    url: '/wms/companyTruck/list',
    method: 'get',
    params: query
  })
}

// 查询客户车辆详细
export function getCompanyTruck(id) {
  return request({
    url: '/wms/companyTruck/' + id,
    method: 'get'
  })
}

// 新增客户车辆
export function addCompanyTruck(data) {
  return request({
    url: '/wms/companyTruck',
    method: 'post',
    data: data
  })
}

// 修改客户车辆
export function updateCompanyTruck(data) {
  return request({
    url: '/wms/companyTruck',
    method: 'put',
    data: data
  })
}

// 删除客户车辆
export function delCompanyTruck(id) {
  return request({
    url: '/wms/companyTruck/' + id,
    method: 'delete'
  })
}

import request from '@/utils/request'


/**
 * 仓单注销管理模块
*/
// 新增期货仓单注销 
export function receiptCancelAdd(data) {
  return request({
    url: '/wms/receiptCancel/add',
    method: "post",
    data
  });
}
// 新增期货仓单注销 （新）
export function receiptCancelAddNew(data) {
  return request({
    url: '/wms/receiptCancel/addNew',
    method: "post",
    data
  });
}
// 新增期货仓单注销 （批量）
export function receiptCancelAddListNew(data) {
  return request({
    url: '/wms/receiptCancel/addListNew',
    method: "post",
    data
  });
}
// 期货仓单注销审核
export function receiptCancelCheck(data) {
  return request({
    url: '/wms/receiptCancel/check',
    method: "post",
    data
  });
}
// 期货仓单批量注销审核
export function checkBatch(data) {
  return request({
    url: '/wms/receiptCancel/checkBatch',
    method: "post",
    data
  });
}

// 获取期货仓单列表
export function getReceiptList(params) {
  return request({
    url: '/wms/receiptCancel/getReceiptList',
    method: "get",
    params
  });
}
// 获取期货仓单列表(新)
export function getReceiptListNew(data) {
  return request({
    url: '/wms/receiptCancel/getReceiptListNew/' + data,
    method: "get",
  });
}
// 根据期货仓单批量获取垛位
export function getReceiptListsNew(data) {
  return request({
    url: '/wms/receiptCancel/getReceiptListsNew/' + data,
    method: "get",
  });
}

// 根据选择的云仓单号获取库房垛位库存数据
export function getStockList(params) {
  return request({
    url: '/wms/receiptCancel/getStockList',
    method: "get",
    params
  });
}

// 查询期货仓单注销列表
export function receiptCancelList(params) {
  return request({
    url: '/wms/receiptCancel/list',
    method: "get",
    params
  });
}

// 期货仓单注销撤销
export function receiptCancelUndo(data) {
  return request({
    url: '/wms/receiptCancel/undo',
    method: "post",
    data
  });
}

// 修改期货仓单注销
export function receiptCancelEditNew(data) {
  return request({
    url: '/wms/receiptCancel/editNew',
    method: "post",
    data
  });
}

// 获取期货仓单注销详细信息
export function receiptCancelById(id) {
  return request({
    url: '/wms/receiptCancel/' + id,
    method: 'get'
  })
}
// 获取期货仓单注销详细信息(新)
export function receiptCancelByIdNew(id) {
  return request({
    url: '/wms/receiptCancel/info/' + id,
    method: 'get'
  })
}


// 删除期货仓单注销
export function receiptDeleteById(id) {
  return request({
    url: '/wms/receiptCancel/' + id,
    method: 'delete'
  })
}

/**
 * 仓单出库管理模块
*/
// 查询期货仓单出库办理列表
export function receiptOutList(params) {
  return request({
    url: '/wms/receiptOut/list',
    method: 'get',
    params
  })
}

// 获取期货仓单出库办理详细信息
export function receiptOutById(id) {
  return request({
    url: '/wms/receiptOut/' + id,
    method: 'get'
  })
}

// 开提单
export function receiptOutAddAppointment(data) {
  return request({
    url: '/wms/receiptOut/addAppointment',
    method: 'post',
    data
  })
}

// 转现货
export function receiptOutChangeToSpot(params) {
  return request({
    url: '/wms/receiptOut/changeToSpot',
    method: "post",
    params
  });
}


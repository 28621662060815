<template>
  <!-- 父页面引入 -->
  <!-- <AddCustom v-model="modelValue" @submitForm="submitForm"></AddCustom> -->
  <el-dialog
    :before-close="cancel"
    :close-on-click-modal="false"
    title="选择客户"
    v-model="modelValue"
    width="1000px"
    append-to-body
  >
    <el-form
      :model="queryParams"
      ref="queryRef"
      :inline="true"
      label-width="100px"
    >
      <el-form-item label="客户名称" prop="enterpriseName">
        <el-input
          v-model="queryParams.enterpriseName"
          placeholder="请输入客户名称"
          clearable
        />
      </el-form-item>
      <el-form-item label="客户编码" prop="enterpriseCode">
        <el-input
          v-model="queryParams.enterpriseCode"
          placeholder="请输入客户编码"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button class="blue-btn" icon="Search" @click="handleQuery">
          搜索
        </el-button>
        <el-button class="gray-btn" icon="Refresh" @click="resetQuery">
          重置
        </el-button>
      </el-form-item>
    </el-form>

    <el-table
      ref="singleTable"
      v-loading="loading"
      :data="companyList"
      @select="selectChange"
    >
      <el-table-column type="selection" width="40" />
      <el-table-column label="序号" width="50">
        <template #default="scope">
          {{
            queryParams.pageSize * (queryParams.pageNum - 1) + scope.$index + 1
          }}
        </template>
      </el-table-column>
      <el-table-column label="客户名称" prop="enterpriseName" />
      <el-table-column label="客户编码" prop="enterpriseCode" />
      <el-table-column
        label="客户类型"
       
        prop="enterpriseType"
        v-if="props.enterpriseType == 1"
      />
      <el-table-column
        label="所在城市"
       
        prop="city"
        v-if="props.enterpriseType == 1"
      />
      <el-table-column
        label="客户联系电话"
       
        prop="contactPhone"
        v-if="props.enterpriseType == 2"
      />
      <el-table-column
        label="收货地址"
       
        prop="address"
        width="200"
        v-if="props.enterpriseType == 2"
      />
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      v-model:page="queryParams.pageNum"
      v-model:limit="queryParams.pageSize"
      @pagination="getList"
    />

    <template #footer>
      <div class="dialog-footer">
        <el-button class="gray-btn" @click="cancel">取 消</el-button>
        <el-button class="blue-btn" @click="submitForm">确 定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive, onMounted } from "vue"
// import { getCompanyList } from "@/api/wms/components/index";
import { customlist } from "@/api/wms/custom"

import { Navbar } from "@/layout/components"
const { proxy } = getCurrentInstance()
const emits = defineEmits(["update:modelValue", "submitForm"])
const props = defineProps({
  // 显示状态
  modelValue: {
    type: Boolean,
    default: false,
  },
  // 客户类型 1入库预约、2出库预约
  companyType: {
    type: Number,
    default: 1,
  },
})

const data = reactive({
  total: 0,
  queryParams: {
    pageNum: 1,
    pageSize: 10,
    enterpriseName: null,
    enterpriseCode: null,
  },
  loading: false,
  companyList: [],
  selected: null,
})
const { total, queryParams, loading, companyList, selected } = toRefs(data)

/** 搜索按钮操作 */
function handleQuery() {
  queryParams.value.pageNum = 1
  getList()
}
/** 重置按钮操作 */
function resetQuery() {
  proxy.resetForm("queryRef")
  handleQuery()
}
/** 查询垛位管理列表 */
function getList() {
  loading.value = true
  customlist(queryParams.value).then((response) => {
    companyList.value = response.rows
    total.value = response.total
    loading.value = false
  })
}
// 多选框选中数据
function selectChange(selection) {
  if (selection.length > 1) {
    const del_row = selection.shift()
    proxy.$refs.singleTable.toggleRowSelection(del_row, false)
  }
  selected.value = selection[0]
}

// 取消按钮
function cancel() {
  emits("update:modelValue", false)
  proxy.$refs.singleTable.clearSelection()
  selected.value = null
}

/** 提交按钮 */
function submitForm() {
  // console.log('mmmmmmm',selected.value)
  if (selected.value) {
    emits("submitForm", selected.value)
    cancel()
  } else {
    proxy.$modal.msgError("选择客户")
  }
}
onMounted(() => {
  handleQuery()
})
</script>

<style scoped>
:deep(th .el-checkbox) {
  display: none;
}
</style>

<template>
  <el-dialog v-model="stockVisible" title="库存盘点" width="60%" append-to-body>
    <p style="margin-top: -20px"><strong><b>注意：盘点范围为1</b></strong></p>
    <el-table :data="stockData" :border="border" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="序号" width="55">
        <template #default="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="cloudCode" label="云仓单号" width="160" />
      <el-table-column prop="productName" label="物品名称" show-overflow-tooltip />
      <el-table-column prop="specificationVal" label="规格" show-overflow-tooltip />
      <el-table-column prop="remainWeight" label="磅重(吨)" show-overflow-tooltip />
      <el-table-column label="实盘重量" width="120">
        <template #default="scope">
          <el-input v-model="scope.row.firmOfferWeight"
                    placeholder=""  @input="updateProfitLoss(scope.row)" @keypress="handleKeyPress" ></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="weight" label="盈亏重量" show-overflow-tooltip />
      <el-table-column prop="remainCount" label="系统数量" show-overflow-tooltip />
      <el-table-column label="实盘数量" width="120">
        <template #default="scope">
          <el-input  placeholder=""   v-model="scope.row.firmOfferCount"  @input="updateProfitLoss(scope.row)" @keypress="handleKeyPress"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="count" label="盈亏数量" show-overflow-tooltip >
      </el-table-column>
<!--      <el-table-column prop="result" label="盘点结果" show-overflow-tooltip >-->
<!--        <template #default="scope">-->
<!--          <template v-if="scope.row.count < 0 &&  scope.row.varietyCode === 'CF'">-->
<!--            <span style="color: red">盘亏</span>-->
<!--          </template>-->
<!--          <template v-if="scope.row.count >= 0 &&  scope.row.varietyCode === 'CF'">-->
<!--            <span style="color: green">盘盈</span>-->
<!--          </template>-->
<!--          <template v-if="scope.row.weight < 0 &&  scope.row.varietyCode !== 'CF'">-->
<!--            <span style="color: red">盘亏</span>-->
<!--          </template>-->
<!--          <template v-if="scope.row.weight >= 0 &&  scope.row.varietyCode !== 'CF'">-->
<!--            <span style="color: green">盘盈</span>-->
<!--          </template>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="备注" width="120">
        <template #default="scope">
          <el-input v-model="scope.row.remark" placeholder="请输入备注" ></el-input>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: right;">
      <el-button type="primary" class="confirm-button" @click="confirmStockTaking">确定盘库</el-button>
    </div>
  </el-dialog>
</template>
<script setup name="indexEdit">
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import {
  firmOfferStock,
} from '@/api/wms/appointment/inAppointment'

const store = useStore()
const { proxy } = getCurrentInstance()
const { capacity_unit } = proxy.useDict('capacity_unit')
const stockVisible = ref(false)

const props = defineProps({
  scene: {
    type: String,
    default: 'add'
  },
  stockData: {
    type: Array,
    default: () => {
      return []
    }
  },
  border: {
    type: Boolean,
    default: false
  },
  carVisible: {
    type: Boolean,
    default: false
  },
  isShowProduct: {
    type: Boolean,
    default: false
  },
  isShowRemark: {
    type: Boolean,
    default: false
  },
  countName: {
    type: String,
    default: '数量'
  },
  weightName: {
    type: String,
    default: '分配重量'
  },
  companyCode:{
    type: String,
    default: ''
  },
  productCode:{
    type: String,
    default: ''
  },
  wbOrderType: {
    type: String,
    default: 'GOODS_IN'
  },
  showWeight: {
    type: Boolean,
    default: false
  },
  isShowFile: {
    type: Boolean,
    default: false
  },
  stacks: {
    type: Array,
    default: []
  }
})
const emit = defineEmits(['removeIndex', 'TableValidate'])
const selectedRows = ref([]);

const handleSelectionChange = (selection) => {
  selectedRows.value = selection;
}

// 处理键盘按下事件，限制输入为数字并最多保留四位小数
function  handleKeyPress(event) {
  const charCode = (typeof event.which === "undefined") ? event.keyCode : event.which;
  const charStr = String.fromCharCode(charCode);
  const regex = /^\d*\.?\d{0,4}$/;
  if (!regex.test(charStr)) {
    event.preventDefault();
  }
}
function updateProfitLoss(row) {
  if (row.firmOfferWeight !== undefined && row.remainWeight !== undefined && row.remainWeight !== '' && row.firmOfferWeight !== '') {
    row.firmOfferWeight = row.firmOfferWeight.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
    row.firmOfferWeight = row.firmOfferWeight.replace(/^\./g, ''); // 首位是小数点时，去掉前面的点
    row.firmOfferWeight = row.firmOfferWeight.replace(/\.{2,}/g, '.'); // 去除连续的多个小数点
    row.firmOfferWeight = row.firmOfferWeight.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
  }
  if (row.firmOfferCount !== undefined && row.remainCount !== undefined && row.remainCount !== ''  && row.firmOfferCount !== '') {
    row.firmOfferCount = row.firmOfferCount.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
    row.firmOfferCount = row.firmOfferCount.replace(/^\./g, ''); // 首位是小数点时，去掉前面的点
    row.firmOfferCount = row.firmOfferCount.replace(/\.{2,}/g, '.'); // 去除连续的多个小数点
    row.firmOfferCount = row.firmOfferCount.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
  }

  if (row.firmOfferWeight !== undefined && row.remainWeight !== undefined && row.remainWeight !== '' && row.firmOfferWeight !== '') {
    row.weight = (parseFloat(row.remainWeight) - parseFloat(row.firmOfferWeight)).toFixed(2);
  }else {
    row.weight = 0;
  }
  if (row.firmOfferCount !== undefined && row.remainCount !== undefined && row.remainCount !== ''  && row.firmOfferCount !== '') {
    row.count = (parseFloat(row.remainCount) - parseFloat(row.firmOfferCount)).toFixed(2);
  }else {
    row.count = 0
  }
}

import { getCurrentInstance } from 'vue';
const instance = getCurrentInstance(); // 获取当前组件实例
const confirmAndRefresh = () => {
  instance.emit('confirm-and-refresh'); // 触发自定义事件
}
const confirmStockTaking = () => {
  if (selectedRows.value.length === 0) {
    ElMessage({
      type: 'warning',
      message: '请勾选盘库数据'
    });
    return;
  } else {
    const missingWeight = selectedRows.value.some(row => !row.firmOfferWeight);
    if (missingWeight) {
      ElMessage({
        type: 'warning',
        message: '实盘重量不能为空，请填写实盘重量'
      });
      return;
    }
    const count = selectedRows.value.some(row => !row.firmOfferCount);
    if (count) {
      ElMessage({
        type: 'warning',
        message: '实盘数量不能为空，请填写实盘数量'
      });
      return;
    }
    const remark = selectedRows.value.some(row => !row.remark || row.remark.trim() === '');
    if (remark) {
      ElMessage({
        type: 'warning',
        message: '请输入盘库原因'
      });
      return;
    }

    const firmOfferWeight = parseFloat(selectedRows.value.map(row => row.firmOfferWeight));
    const remainWeight = parseFloat(selectedRows.value.map(row => row.remainWeight));
    if (!(firmOfferWeight >= (remainWeight - 1) && firmOfferWeight <= (remainWeight + 1))) {
      ElMessage({
        type: 'warning',
        message: '盘库重量范围为1，请及时更改'
      });
      return;
    }

    const firmOfferCount = parseFloat(selectedRows.value.map(row => row.firmOfferCount));
    const remainCount = parseFloat(selectedRows.value.map(row => row.remainCount));
    if (!(firmOfferCount >=( remainCount - 1) && firmOfferCount <= (remainCount + 1))) {
      ElMessage({
        type: 'warning',
        message: '盘库数量范围为1，请及时更改'
      });
      return;
    }

    const selectedRowsJson = JSON.stringify(selectedRows.value);
    firmOfferStock(selectedRowsJson)
        .then(response => {
          const { code, msg } = response;
          if (code === 200) {
            ElMessage({
              type: 'success',
              message: '盘库成功'
            });
            stockVisible.value = false;
            confirmAndRefresh()
          }else {
            ElMessage({
              type: 'error',
              message: msg
            });
          }
        })
  }

};


</script>
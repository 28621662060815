<script lang="tsx">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'StackStock',
})
</script>
<template>
<el-dialog :model-value="visible" :title="title" append-to-body width="80%" @close="closeDialog">
  <el-form ref="stackCloudDetailRef" :model="formParams" :inline="true" label-width="68px" class="custom-form">
    <el-form-item label="云仓单号" prop="cloudCode">
      <el-input v-model="formParams.cloudCode" clearable placeholder="请输入" />
    </el-form-item>
    
    <el-form-item label="客户名称" prop="companyName">
      <el-input v-model="formParams.companyName" clearable placeholder="请输入" />
    </el-form-item>
    <el-form-item label="车牌号" prop="carPlateNum">
      <el-input v-model="formParams.carPlateNum" clearable placeholder="请输入" />
    </el-form-item>
    <el-form-item label="日期" prop="startTime">
      <el-date-picker v-model="businessTime" type="daterange" value-format="YYYY-MM-DD" range-separator="-" start-placeholder="开始日期"
        end-placeholder="结束日期" style="width: 210px;" @change="changeBusiness" />
    </el-form-item>
    <el-form-item>
      <el-button @click="reset(stackCloudDetailRef)">清空</el-button>
      <el-button type="primary" @click="getData">搜索</el-button>
    </el-form-item>
  </el-form>
  <el-table :data="tableData" v-loading="loading" height="300px">
    <el-table-column type="index" label="序号" width="55" />
    <el-table-column prop="cloudCode" label="云仓单号" width="170" />
    <el-table-column prop="companyName" label="客户名称" width="100" show-overflow-tooltip />
    <el-table-column prop="productName" :label=" varietyCode === 'CF' ? '批次号' : '商品名称'" width="130" show-overflow-tooltip />
    <el-table-column prop="businessTime" label="出入库日期" width="160" />
    <el-table-column prop="billCode" label="收发单号" />
    <el-table-column prop="produceCode" label="生产编号" />
    <el-table-column prop="packageType" label="包装方式" />
    <el-table-column prop="carPlateNum" label="车牌号" width="100" />
    <el-table-column prop="totalCount" label="数量" width="100">
      <template #default="{ row }">
        {{ row.totalCount }}
        <dict-tag :options="capacity_unit" :value="countUnit" />
      </template>
    </el-table-column>
    <el-table-column prop="totalWeight" label="重量" width="100">
      <template #default="{ row }">
        {{ row.totalWeight }}
        <dict-tag :options="capacity_unit" :value="weightUnit" />
      </template>
    </el-table-column>
    <el-table-column prop="stackName" label="垛位" />
    <el-table-column prop="businessType" label="业务类型">
      <template #default="{ row }">
        <dict-tag :options="business_type" :value="row.businessType" />
      </template>
    </el-table-column>
    <el-table-column label="凭证" prop="certificateFile">
      <template #default="scope">
        <div v-if="scope.row.certificateFile">
          <ShowFiles :files="scope.row.certificateFile" @getFile="getFile" />
        </div>
      </template>
    </el-table-column>
  </el-table>
  <pagination v-show="count > 0" :total="count" v-model:page="formParams.pageNum"
        v-model:limit="formParams.pageSize" @pagination="getData" />
</el-dialog>
<el-dialog v-model="visiblePDF" title="预览文档" width="80%" append-to-body @close="closePDF">
  <ShowPDF :url="urlPDF" />
</el-dialog>
</template>
<script setup lang="tsx" name="StackStock">
import { ref, reactive, watch, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import type { FormInstance } from 'element-plus'
import { stackCloudDetailList } from '@/api/wms/stock/stock'
const emits = defineEmits(['closeDialog'])
const store = useStore()
const { proxy }: any = getCurrentInstance()
const { cloud_type, capacity_unit, business_type } = proxy.useDict('cloud_type', 'capacity_unit', 'business_type')
const props = defineProps({
  flag: {
    type: String,
    default: 'stackId'
  },
  id: {
    type: [Number, String],
    default: 1
  },
  visible: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  isHistory: {
    type: String,
    default: null
  }
})
const stackCloudDetailRef = ref<FormInstance>()
const tableData = ref([])
const businessTime = ref()
const loading = ref(false)
const count = ref(0)
const formParams = reactive({
  businessTimeBgn: '',
  businessTimeEnd: '',
  companyName: '',
  carPlateNum: '',
  cloudCode: '',
  isHistory: props.isHistory,
  pageNum: 1,
  pageSize: 10
})

const changeBusiness = (val: any) => {
  formParams.businessTimeBgn = ''
  formParams.businessTimeEnd = ''
  if (val && val.length === 0) return
  formParams.businessTimeBgn = `${val[0]} 00:00:00`
  formParams.businessTimeEnd = `${val[1]} 23:59:59`
}

const getData = async () => {
  loading.value = true
  const { code, rows, total }: any = await stackCloudDetailList({
    ...formParams,
    [props.flag]: props.id
  })
  if (code === 200) {
    tableData.value = rows
    count.value = total
    loading.value = false
  } else {
    loading.value = false
  }
}

const reset = (formEl: any) => {
  if (!formEl) return
  formEl.resetFields()
  businessTime.value = ''
  formParams.businessTimeBgn = ''
  formParams.businessTimeEnd = ''
}

const closeDialog = () => {
  emits('closeDialog', false)
}

const visiblePDF = ref(false)
const urlPDF = ref('')
const getFile = (url: string) => {
  urlPDF.value = url
  visiblePDF.value = true
}
const closePDF = () => {
  visiblePDF.value = false
}

const countUnit = ref()
const weightUnit = ref()
const varietyCode = ref()
watch(
  () => store.state.variety.itemObj, 
  (itemObj) => {
    if (itemObj) {
      varietyCode.value = itemObj.varietyCode
      countUnit.value = itemObj.goodsCountUnit
      weightUnit.value = itemObj.goodsWeightUnit
    }
  },
  {
    immediate: true,
  }
)

watch(
  () => props.id,
  (val) => {
    if (val) {
      getData()
    }
  },
  { immediate: true }
)
</script>
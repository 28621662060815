import {
  createApp
} from 'vue'

import Cookies from 'js-cookie'

import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/zh-cn' // 中文语言
import 'element-plus/theme-chalk/index.css'

import '@/assets/styles/index.scss' // global css
import formCreate from '@form-create/element-ui'
import App from './App'
import store from './store'
import router from './router'
import directive from './directive' // directive
import * as echarts from "echarts"
import china from './utils/china.json'

echarts.registerMap("china", china);
// 注册指令
import plugins from './plugins' // plugins
import {
  download
} from '@/utils/request'
//附件预览转码需要
// @ts-ignore
import '@/utils/base64.min.js'
// svg图标
import 'virtual:svg-icons-register'
import SvgIcon from '@/components/SvgIcon'
import elementIcons from '@/components/SvgIcon/svgicon'

import './permission' // permission control

import {
  useDict
} from '@/utils/dict'
import {
  parseTime,
  resetForm,
  addDateRange,
  handleTree,
  selectDictLabel,
} from '@/utils/ruoyi'

// 分页组件
import Pagination from '@/components/Pagination'
// 自定义表格工具组件
import RightToolbar from '@/components/RightToolbar'
// 文件上传组件
import FileUpload from '@/components/FileUpload'
// 图片上传组件
import ImageSingleUpload from '@/components/ImageSingleUpload'

import ImagesUpload from '@/components/ImagesUpload'
// 图片预览组件
import ImagePreview from '@/components/ImagePreview'
// 自定义树选择组件
import TreeSelect from '@/components/TreeSelect'
// 字典标签组件
import DictTag from '@/components/DictTag'
// 用户组件
import AddCustom from '@/components/AddCustom'
import AddCustomSection from '@/components/AddCustomSection'
// 审核组件
import Audit from '@/components/Audit'
// 上传文件组件
import UploadFile from '@/components/UploadFile'
// 内容区域标题
import ContentHeader from '@/components/ContentHeader'
// 统计信息
import StatisticInfo from '@/components/StatisticInfo'
// 查看图片和pdf
import ShowFiles from '@/components/ShowFiles'
// 查看pdf
import ShowPDF from '@/components/ShowPDF'
// 列表页垛位查看
import CustomTooltip from '@/components/CustomTooltip'
// 垛位列表
import StackList from '@/components/StackList'
// 仓单列表
import SelectCloud from '@/components/SelectCloud'
// 云仓单明细
import StackStock from '@/components/StackStock'
// 磅单列表
import WeightTable from '@/components/WeightList'
// 导入
import ImportData from '@/components/ImportData'
// 表格组件
import TableList from '@/components/TableList'
import OrderDetail from '@/views/wms/orderDetail'

// 商品组件
import AddCommodity from '@/components/AddCommodity'
// 出库商品组件
import outboundGoods from '@/components/outboundGoods'
//vue3图片预览插件
import vue3PreviewImage from 'vue3-preview-image'
import imgUpload from '@/components/ImgUpload'
// 商品选择框
import SelectProductName from '@/components/SelectProductName'
import StackListEdit from '@/components/StackList/indexEdit.vue'

const app = createApp(App)

// 全局方法挂载
app.config.globalProperties.useDict = useDict
app.config.globalProperties.download = download
app.config.globalProperties.parseTime = parseTime
app.config.globalProperties.resetForm = resetForm
app.config.globalProperties.handleTree = handleTree
app.config.globalProperties.addDateRange = addDateRange
app.config.globalProperties.selectDictLabel = selectDictLabel
app.config.globalProperties.$echarts = echarts

// 全局组件挂载
app.component('DictTag', DictTag)
app.component('Pagination', Pagination)
app.component('TreeSelect', TreeSelect)
app.component('FileUpload', FileUpload)
app.component('ImageSingleUpload', ImageSingleUpload)
app.component('ImagesUpload', ImagesUpload)
app.component('ImagePreview', ImagePreview)
app.component('RightToolbar', RightToolbar)
app.component('AddCustom', AddCustom)
app.component('AddCustomSection', AddCustomSection)
app.component('Audit', Audit)

app.component('AddCommodity', AddCommodity)
app.component('outboundGoods', outboundGoods)
app.component('imgUpload', imgUpload)
app.component('UploadFile', UploadFile)
app.component('ContentHeader', ContentHeader)
app.component('StatisticInfo', StatisticInfo)
app.component('ShowFiles', ShowFiles)
app.component('ShowPDF', ShowPDF)
app.component('CustomTooltip', CustomTooltip)
app.component('StackList', StackList)
app.component('SelectCloud', SelectCloud)
app.component('StackStock', StackStock)
app.component('WeightTable', WeightTable)
app.component('ImportData', ImportData)
app.component('TableList', TableList)
app.component('OrderDetail', OrderDetail)
app.component('SelectProductName', SelectProductName)
app.component('StackListEdit', StackListEdit)

app.use(vue3PreviewImage)
app.use(router)
app.use(store)
app.use(plugins)
app.use(elementIcons)
app.component('svg-icon', SvgIcon)

directive(app)

// 使用element-plus 并且设置全局的大小
app.use(ElementPlus, {
  locale: locale,
  // 支持 large、default、small
  size: Cookies.get('size') || 'default',
})

app.use(formCreate)

app.mount('#app')
import request from '@/utils/request'

// 用户列表
export function getCompanyList(query) {
  return request({
    url: 'wms/company/list',
    method: 'get',
    params: query,
  })
}
// 入库商品列表
export function getProductInfoList(query) {
  return request({
    url: '/wms/productInfo/list',
    method: 'get',
    params: query,
  })
}

// 出库商品列表
export function getStockSumY(query) {
  return request({
    url: '/wms/stockSum/stockSumList',
    method: 'get',
    params: query,
  })
}

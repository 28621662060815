<template>
  <div class="component-upload-image">
    <el-upload
      :action="uploadImgUrl"
      list-type="picture-card"
      :on-success="handleUploadSuccess"
      :before-upload="handleBeforeUpload"
      :limit="limit"
      :on-error="handleUploadError"
      :on-exceed="handleExceed"
      name="file"
      :data="data"
      :on-remove="handleRemove"
      :show-file-list="true"
      :headers="headers"
      :file-list="fileList"
      :on-preview="handlePictureCardPreview"
      :class="{ hide: fileList.length >= limit }"
    >
      <el-icon class="avatar-uploader-icon"><plus /></el-icon>
    </el-upload>
    <!-- 上传提示 -->
    <div class="el-upload__tip" v-if="showTip">
      请上传
      <template v-if="fileSize">
        大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b>
      </template>
      <template v-if="fileType">
        格式为 <b style="color: #f56c6c">{{ fileType.join("/") }}</b>
      </template>
      的文件
    </div>

    <el-dialog
      v-model="dialogVisible"
      title="预览"
      width="800px"
      append-to-body
    >
      <img
        :src="dialogImageUrl"
        style="display: block; max-width: 100%; margin: 0 auto"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import { getToken } from "@/utils/auth";
import { getFileUrl, getSignature, uploadFile } from '@/api/wms/file'
import { useStore } from 'vuex'
import { computed, reactive } from "vue";
const store = useStore()
const props = defineProps({
  modelValue: [String, Object, Array],
  // 图片数量限制
  limit: {
    type: Number,
    default: 5,
  },
  // 大小限制(MB)
  fileSize: {
    type: Number,
    default: 5,
  },
  // 文件类型, 例如['png', 'jpg', 'jpeg']
  fileType: {
    type: Array,
    default: () => ["png", "jpg", "jpeg"],
  },
  // 是否显示提示
  isShowTip: {
    type: Boolean,
    default: true
  },
});

const { proxy } = getCurrentInstance();
const emit = defineEmits();
const number = ref(0);
const uploadList = ref([]);
const dialogImageUrl = ref("");
const dialogVisible = ref(false);
const baseUrl = import.meta.env.VITE_APP_BASE_API;
const uploadImgUrl = ref(import.meta.env.VITE_APP_BASE_API + "/file/minio/upload"); // 上传的图片服务器地址
const headers = ref({ Authorization: "Bearer " + getToken() });
const fileList = ref([]);
const urlList = ref([])
const showTip = computed(
  () => props.isShowTip && (props.fileType || props.fileSize)
);

// const tenantId = computed(() => store.getters.tenantId)
const data = reactive({
  dir: `${store.getters.tenantId}`
})

watch(() => props.modelValue, val => {
  // console.log('watch',val)
  if (val) {
    
    // handleDown(val)
    // 首先将值转为数组
    // console.log('aaaaa',val.data)
    // fileList.value =  [val.data];
    // // 然后将数组转为对象数组
    // fileList.value = list.map(item => {
    //   if (typeof item === "string") {
    //     item = { name: item, url: item };
    //   }
    //   return item;
    // });
    fileList.value = [];
    urlList.value =[]
    const list =  val.split(',')
    console.log('watch',list)
    for(let [k,v] of list.entries()){
      urlList.value.push(v)
      handleDown(v)
    }
  } else {
    fileList.value = [];
    return [];
  }
},{ deep: true, immediate: true });
// 下载文件
async function handleDown(val) {
  const res = await getFileUrl(val)
  console.log('handleDown',res)
  fileList.value.push({name:'',url:res.data})
}
// 删除图片
function handleRemove(file, files) {
  // console.log('a', file.url)
  // console.log('b', files[0].url)
  // let indexAt = []
  // for(let [k,v] of fileList.value.entries()){
  //   console.log('c',v.url)
  //   console.log('d',file.url)
  //    if(v.url!=file.url){
  //     indexAt.push(k)
  //    }
  // }
  // console.log('k',indexAt)
  // const delUrl
  console.log(fileList.value)
  const remainUrl = fileList.value.map((item)=>{
     let url1 = item.url.split('?')[0]
     let url2Arr = url1.split("/")
     let endUrl = url2Arr[url2Arr.length-1]
    return endUrl
  })
  console.log('remainUrl',remainUrl)
  emit("update:modelValue",remainUrl.join(','));
}

// 上传成功回调
function handleUploadSuccess(res) {
  proxy.$modal.closeLoading();
  urlList.value.push(res.data.link)
  const sentData = [...new Set(urlList.value)]
  // let sentValArr = []
  // for(let [k,v] of fileList.value.entries()){
  //   sentValArr.push(v.url)
  // }
  // let sentVal = sentValArr.join(',')
  // uploadList.value.push({ name: res.data.link, url: res.data.link })
  // console.log('handleUploadSuccess',sentData.join(','))
  // const list = props.modelValue? props.modelValue.split(','):[]
  
  // let imagsRes = list.push(res.data.link)

  emit("update:modelValue",sentData.join(','));
  // handleDown(res.data.link)
  // const res = await getFileUrl( res.data.link)
  // uploadList.value.push({ name: res.data.link, url: res.data.link });
  // if (uploadList.value.length === number.value) {
  //   fileList.value = fileList.value.concat(uploadList.value);
  //   uploadList.value = [];
  //   number.value = 0;
  //   emit("update:modelValue", listToString(fileList.value));
  //   proxy.$modal.closeLoading();
  // }
}

// 上传前loading加载
function handleBeforeUpload(file) {
  console.log('file', file)
  let isImg = false;
  if (props.fileType.length) {
    let fileExtension = "";
    if (file.name.lastIndexOf(".") > -1) {
      fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
    }
    isImg = props.fileType.some(type => {
      if (file.type.indexOf(type) > -1) return true;
      if (fileExtension && fileExtension.indexOf(type) > -1) return true;
      return false;
    });
  } else {
    isImg = file.type.indexOf("image") > -1;
  }
  if (!isImg) {
    proxy.$modal.msgError(
      `文件格式不正确, 请上传${props.fileType.join("/")}图片格式文件!`
    );
    return false;
  }
  if (props.fileSize) {
    const isLt = file.size / 1024 / 1024 < props.fileSize;
    if (!isLt) {
      proxy.$modal.msgError(`上传头像图片大小不能超过 ${props.fileSize} MB!`);
      return false;
    }
  }
  proxy.$modal.loading("正在上传图片，请稍候...");
  number.value++;
}

// 文件个数超出
function handleExceed() {
  proxy.$modal.msgError(`上传文件数量不能超过 ${props.limit} 个!`);
}

// 上传失败
function handleUploadError() {
  proxy.$modal.msgError("上传图片失败");
  proxy.$modal.closeLoading();
}

// 预览
function handlePictureCardPreview(file) {
  dialogImageUrl.value = file.url;
  dialogVisible.value = true;
}

// 对象转成指定字符串分隔
function listToString(list, separator) {
  let strs = "";
  separator = separator || ",";
  for (let i in list) {
    if (undefined !== list[i].url && list[i].url.indexOf("blob:") !== 0) {
      strs += list[i].url.replace(baseUrl, "") + separator;
    }
  }
  return strs != "" ? strs.substr(0, strs.length - 1) : "";
}
</script>
<style lang="scss" scoped>
  :deep{
    .el-upload{
    width: 100px;
    height: 100px;
  }   
   .is-success{
        width: 100px;
        height: 100px;
    }
  }
</style>
<template>
  <el-form ref="cloudFormRef" :model="cloudFormModel" :inline="true" label-width="68px" class="custom-form">
    <el-form-item label="云仓单号" prop="cloudCode" v-if="flag !== 'stackProductList'">
      <el-input v-model="cloudFormModel.cloudCode" placeholder="请输入云仓单号" @keyup.enter="getCloudData" />
    </el-form-item>
    <el-form-item label="客户名称" prop="companyName" v-if="flag !== 'stackProductList'">
      <el-input v-model="cloudFormModel.companyName" placeholder="请输入客户名称" @keyup.enter="getCloudData" />
    </el-form-item>
    <el-form-item :label="varietyId === 'CF' ? '批次号' : '商品名称'" prop="productName"
      v-if="flag !== 'stackProductList' || varietyId === 'CF'">
      <el-input v-model="cloudFormModel.productName" :placeholder="varietyId === 'CF' ? '请输入批次号' : '请输入商品名称'"
        @keyup.enter="getCloudData" />
    </el-form-item>
    <el-form-item label="品牌" prop="brandName" v-if="flag !== 'stackProductList'">
      <el-input v-model="cloudFormModel.brandName" placeholder="请输入品牌" @keyup.enter="getCloudData" />
    </el-form-item>
    <el-form-item label="垛位" prop="roomStack">
      <StackSelect ref="stackSelect" v-model="cloudFormModel.roomStack" placeholder="请选择垛位" @change="getCloudData"
        clearable />
    </el-form-item>
    <el-form-item label="">
      <el-button @click="resetCloudForm(cloudFormRef)">清空</el-button>
      <el-button type="primary" @click="getCloudData">搜索</el-button>
    </el-form-item>
  </el-form>
  <el-table ref="cloudTableRef" :data="tableData" size="small" v-loading="loading" highlight-current-row
    @row-click="rowClick" @selection-change="handleSelectionChange" :row-key="rowKey">
    <el-table-column width="50" fixed="left" label="单选" v-if="!isMultiSelect">
      <template #default="{ row }">
        <el-radio v-model="cloudCode" :label="row.cloudCode">
          <span></span>
        </el-radio>
      </template>
    </el-table-column>
    <el-table-column width="50" fixed="left" type="selection" prop="isSelected" reserve-selection v-else>
    </el-table-column>
    <el-table-column type="index" label="序号" />
    <el-table-column prop="cloudCode" label="云仓单号" v-if="flag !== 'stackProductList'" />
    <el-table-column prop="companyName" label="客户名称" v-if="flag !== 'stackProductList'" />
    <el-table-column prop="productName" :label="varietyId === 'CF' ? '批次号' : '商品名称'" />
    <el-table-column prop="roomName" label="库房名称" v-if="isShowRoomStack" />
    <el-table-column prop="stackName" label="垛位名称" v-if="isShowRoomStack" />
    <el-table-column prop="registerCount" label="仓单量" v-if="flag !== 'stackProductList'">
      <template #default="{ row }">
        {{ row.remainWeight }}
        <dict-tag :options="capacity_unit" :value="row.weightUnit" />
        /
        {{ row.remainCount }}
        <dict-tag :options="capacity_unit" :value="row.countUnit" />
      </template>
    </el-table-column>
    <el-table-column prop="availableCount" label="可用量">
      <template #default="{ row }">
        {{ row.availableWeight }}
        <dict-tag :options="capacity_unit" :value="row.weightUnit" />
        /
        {{ row.availableCount }}
        <dict-tag :options="capacity_unit" :value="row.countUnit" />
      </template>
    </el-table-column>
    <el-table-column prop="inTime" label="入库日期" v-if="flag !== 'stackProductList'" />
    <el-table-column prop="brandName" label="品牌" />
    <el-table-column prop="gradeName" label="等级" />
    <el-table-column prop="placeOrgan" label="产地" />
    <el-table-column prop="specificationVal" label="规格" v-if="flag !== 'stackProductList'">
      <template #default="{ row }">
        {{ row.specificationVal }}
        <dict-tag :options="capacity_unit" :value="row.weightUnit" />
        /
        <dict-tag :options="capacity_unit" :value="row.countUnit" />
      </template>
    </el-table-column>
    <el-table-column prop="years" label="年份" v-if="flag !== 'stackProductList'" />
<!--    <el-table-column label="操作" fixed="right">-->
<!--      <template #default="scope">-->
<!--        <el-button type="text" @click="takeStock(scope.row)">库存盘点</el-button>-->
<!--      </template>-->
<!--    </el-table-column>-->
  </el-table>
  <FirmOffer @confirm-and-refresh="closeChildComponentAndRefreshList()" v-if="stockVisible" v-model="stockData"
    :stockVisible="stockVisible" :stockData="stockData" />

  <div class="cloud-footer">
    <pagination v-show="count > 0" :total="count" v-model:page="pageNum" v-model:limit="pageSize"
      @pagination="getCloudData" />
  </div>
</template>
<script setup name="SelectClouds">
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { selectByCompanyProductCloud, selectByCompanyProductRoomStack } from "@/api/wms/stock/stock"
import { receiptRegisterRegisterList, stackProductList } from "@/api/wms/futures/receiptRegister"
import { getReceiptListNew } from "@/api/wms/futures/index"
import StackSelect from "@/components/Wms/StackSelect/index.vue"
import FirmOffer from "@/components/StackList/firmOffer.vue";
import { selectCloudByCompanyProductStack } from "@/api/wms/appointment/inAppointment";
const store = useStore()
const { proxy } = getCurrentInstance()
const stockVisible = ref(false)
const stockData = ref([])
const { capacity_unit } = proxy.useDict('capacity_unit')
const props = defineProps({
  cloudType: {
    type: String,
    default: ''
  },
  flag: {
    type: String,
    default: 'selectByCompanyProductCloud'
  },
  isMultiSelect: {
    type: Boolean,
    default: false
  },
  rowKey: {
    type: String,
    default: 'id'
  },
  companyId: {
    type: String,
    default: null
  },
  productCode: {
    type: String,
    default: null
  }
})
const actions = {
  'selectByCompanyProductCloud': selectByCompanyProductCloud,
  'receiptRegisterRegisterList': receiptRegisterRegisterList,
  'getReceiptListNew': getReceiptListNew,
  'selectByCompanyProductRoomStack': selectByCompanyProductRoomStack,
  'stackProductList': stackProductList
}
const loading = ref(false)
const cloudCode = ref(null)
const row = ref()
const cloudFormRef = ref()
const cloudFormModel = ref({
  cloudType: props.cloudType,
  cloudCode: null,
  companyName: null,
  productName: null,
  brandName: null,
  varietyCode: store.state.variety.varietyId,
  roomStack: [],
  roomId: null,
  stackId: null,
  companyId: props.companyId,
  tenantId: store.state.user.tenantId,
  productCode: props.productCode
})
const pageNum = ref(1)
const pageSize = ref(10)
const count = ref(0)
const tableData = ref([])

const takeStock = (row,) => {
  console.log("row" + row)
  const data = {
    cloudCode: row.cloudCode,
    productCode: row.productCode,
    companyCode: row.companyCode,
    roomId: row.roomId,
    stackId: row.stackId,
  };
  console.log("data", data);
  const { code, rows } = selectCloudByCompanyProductStack(data)
    .then(response => {
      const { code, rows } = response;
      console.log("code", code);
      if (code === 200) {
        stockVisible.value = true
        stockData.value = rows
      }
    })
}

const closeChildComponentAndRefreshList = () => {
  stockVisible.value = false;
  getCloudData()
};

const getCloudData = async () => {
  cloudCode.value = null
  loading.value = true

  const { code, rows, total } = await actions[props.flag]({

    ...cloudFormModel.value,
    roomId: cloudFormModel.value.roomStack[0],
    stackId: cloudFormModel.value.roomStack[1],
    allowOperate: '1',
    pageNum: pageNum.value,
    pageSize: pageSize.value
  })
  if (code === 200) {
    rows.forEach(item => {
      item.isSelected = false
    })
    tableData.value = rows
    count.value = total
    if (props.flag === 'getReceiptListNew') {
      tableData.value.forEach(item => {
        item.companyName = item.registerCompanyName
        item.remainWeight = item.registerWeight
        item.remainCount = item.registerCount
      })
    }
    loading.value = false
  } else {
    loading.value = false
  }
}
const resetCloudForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()

}


// 单选
const cloudTableRef = ref()
const rowClick = (val) => {
  cloudTableRef.value.setCurrentRow(val)
  cloudCode.value = val.cloudCode
  row.value = val
}

const varietyId = computed(() => {
  return store.state.variety.varietyId
})

// 获取选中仓单列表
const selectItems = ref([])
const selectDic = ref([])
const handleSelectionChange = (val) => {
  selectItems.value = val
}

onMounted(() => {
  console.log('onMounted')
  selectItems.value = []
  getCloudData()
})
const isShowRoomStack = ref(false)
watch(() => props.flag,
  (value) => {
    isShowRoomStack.value = false
    if (value === 'getReceiptListNew' || value === 'stackProductList' || value === 'selectByCompanyProductRoomStack') {
      isShowRoomStack.value = true
    }
  },
  {
    immediate: true,
  }
)

defineExpose({
  cloudCode,
  row,
  selectItems
})
</script>
<style lang="scss" scoped>
.cloud-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}
</style>
import request from '@/utils/request'

// 用户列表
export function organizationList(query) {
  return request({
    url: 'wms/enterprise/orgComboboxData',
    method: 'get',
    params: query,
  })
}
//提交
export function addCustom(query) {
  return request({
    url: 'wms/enterprise/add',
    method: 'post',
    data: query,
  })
}
//客户审核列表
export function customExamlist(query) {
  return request({
    url: 'wms/enterprise/addAuditList',
    method: 'post',
    data: query,
  })
}

//客户管理列表
export function customlist(query) {
  return request({
    url: 'wms/enterprise/list',
    method: 'get',
    params: query,
  })
}
//客户管理列表所有
export function customlistAll(query) {
  return request({
    url: 'wms/enterprise/listAll',
    method: 'post',
    data: query,
  })
}
// 查询某个仓库的客户列表
export function listwh(query) {
  return request({
    url: "/wms/stockSum/stockSumCompanyList",
    method: "get",
    params: query,
  });
}

//变更审核

export function changeExamlist(query) {
  return request({
    url: 'wms/enterprise/editAuditList',
    method: 'get',
    params: query,
  })
}
//变更记录

export function changeHistorylist(query) {
  return request({
    url: 'wms/enterprise/changeRecord',
    method: 'get',
    params: query,
  })
}
//审核
export function audit(query) {
  return request({
    url: 'wms/enterprise/audit',
    method: 'post',
    data: query,
  })
}
//变更提交
export function editCustom(query) {
  return request({
    url: 'wms/enterprise/edit',
    method: 'post',
    data: query,
  })
}
//获取详情
export function queryDetail(query) {
  console.log('bbbb', query)
  return request({
    url: `wms/enterprise/${query.Id}`,
    method: 'get',
    query: query,
  })
}

//重置密码

export function resetPwd(query) {
  return request({
    url: `wms/enterprise/resetPwd`,
    method: 'post',
    data: query,
  })
}
//导入
export function importData(query) {
  return request({
    url: `wms/enterprise/importData`,
    method: 'post',
    data: query,
  })
}
//下载模版

export function importTemplate(query) {
  return request({
    url: `wms/enterprise/importTemplate`,
    method: 'get',
    query: query,
  })
}
//生成邀请码
export function getEnterpriseCode(id) {
  return request({
    url: `wms/enterprise/generateCode/` + id,
    method: 'get',
  })
}
//删除客户
export function updateStatus(id) {
  return request({
    url: `wms/enterprise/updateStatus/` + id + `/0`,
    method: 'get',
  })
}
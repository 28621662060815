<template>
  <div>
    <div class="customer-header">
      <div class="customer-header-top">
        <div class="logo">
          <img :src="logo" />
          智慧仓储监管平台
        </div>
        <navbar @setLayout="setLayout" />
      </div>

      <el-tabs v-model="activeName" @tab-click="handleClick">
        <template v-for="(item, index) in sidebarRouters" :key="index">
          <el-tab-pane :label="item.meta ? item.meta.title : '首页'" :name="index" v-if="!item.hidden">
          </el-tab-pane>
        </template>
      </el-tabs>
    </div>
    <div :class="classObj" class="app-wrapper" :style="{ '--current-color': theme }">
      <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
      <div :style="{
        position: 'absolute',
        left: store.state.app.sidebar.opened ? '220px' : '62px',
        width: '50px',
        height: '90px',
        backgroundColor: 'none',
      }"></div>

      <sidebar v-if="!sidebar.hide" class="sidebar-container" :activeName="activeName * 1"
        :menuDataUpdate="menuDataUpdate" />
      <div :class="{ hasTagsView: needTagsView, sidebarHide: sidebar.hide }" class="main-container">
        <tags-view v-if="needTagsView" />
        <!-- <div :class="{ 'fixed-header': fixedHeader }">
          <navbar @setLayout="setLayout" />
          <tags-view v-if="needTagsView" />
        </div> -->
        <app-main />
        <settings ref="settingRef" />
      </div>
    </div>
  </div>
</template>

<script setup>
let { proxy } = getCurrentInstance()
const topbarRouters = computed(() =>
  store.getters.topbarRouters.filter((item) => !item.hidden)
)
import logo from "@/assets/logo/logo.png"
import { useWindowSize } from "@vueuse/core"
import Sidebar from "./components/Sidebar/index.vue"
import { AppMain, Navbar, Settings, TagsView } from "./components"
import defaultSettings from "@/settings"
import screenfull from 'screenfull'
const store = useStore()
const theme = computed(() => store.state.settings.theme)
const sideTheme = computed(() => store.state.settings.sideTheme)
const sidebar = computed(() => store.state.app.sidebar)
const device = computed(() => store.state.app.device)
const needTagsView = computed(() => store.state.settings.tagsView)
const fixedHeader = computed(() => store.state.settings.fixedHeader)
const activeName = ref(localStorage.getItem('activeName') * 1 || 0)
const sidebarRouters = computed(() =>
  store.getters.sidebarRouters.filter((item) => !item.hidden)
)
const menuDataUpdate = ref([])
const classObj = computed(() => ({
  hideSidebar: !sidebar.value.opened,
  openSidebar: sidebar.value.opened,
  withoutAnimation: sidebar.value.withoutAnimation,
  mobile: device.value === "mobile",
}))

const { width, height } = useWindowSize()
const WIDTH = 992 // refer to Bootstrap's responsive design
watchEffect(() => {
  if (device.value === "mobile" && sidebar.value.opened) {
    store.dispatch("app/closeSideBar", { withoutAnimation: false })
  }
  if (width.value - 1 < WIDTH) {
    store.dispatch("app/toggleDevice", "mobile")
    store.dispatch("app/closeSideBar", { withoutAnimation: true })
  } else {
    store.dispatch("app/toggleDevice", "desktop")
  }
})
const setCurrentMenu = () => {
  menuDataUpdate.value = []
  let idx = activeName.value || 0
  if (idx >= sidebarRouters.value.length) {
    idx = 0
  }
  console.log('idx', idx, sidebarRouters.value, activeName.value)
  menuDataUpdate.value = sidebarRouters.value[idx].children
}
setCurrentMenu()
function handleClick(tab, event) {
  console.log('sidebarRouters', sidebarRouters.value)
  setCurrentMenu()
  const idx = activeName.value || 0
  const oldActiveName = localStorage.getItem('activeName')
  localStorage.setItem('activeName', activeName.value)
  let path = sidebarRouters.value[idx].fullPath
  if (path) {
    if (path.indexOf('largescreen') >= 0) {
      // if (!screenfull.isFullscreen) {
      //   screenfull.toggle()
      // }
      // location.href = '/largescreen/homePage/index'
    }
    const currentPath = path.substring(0, path.lastIndexOf('/'))
    if (currentPath === localStorage.getItem('openKey')) {
      localStorage.setItem('openKey', currentPath)
      localStorage.setItem('activeName', oldActiveName)
    }
  }
}
function handleClickOutside() {
  store.dispatch("app/closeSideBar", { withoutAnimation: false })
}

const settingRef = ref(null)
function setLayout() {
  settingRef.value.openSetting()
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.module.scss";

.customer-header {
  padding: 12px 20px;
  background-image: url('../assets/images/nav-bg.png');
  background-size: cover;
  height: 120px;
  overflow: hidden;

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    cursor: default;

    .logo {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 304px;
      height: 33px;
      font-size: 20px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #1D1F80;
    }

    img {
      // width: 304px;
      height: 33px;
    }
  }
}

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
  background: #F5F5F5;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  // height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$base-sidebar-width});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 70px);
}

.sidebarHide .fixed-header {
  width: 100%;
}

.mobile .fixed-header {
  width: 100%;
}

:deep(.el-tabs__nav-wrap::after) {
  background-color: transparent;
}

:deep(.el-tabs__item) {
  // font-size: 16px;
  font-size: 14px;
}
</style>

<template>
  <div class="statistic">
    <slot>
      <div v-for="item in info" :key="item.field" class="statistic-item">
        {{ item.title }}
        <span class="count">{{ item[item.field] }}</span>
        <span v-if="item.type && item.type === 'weight'">
          <dict-tag :options="capacity_unit" :value="weightUnit" />
        </span>
        <span v-if="item.type && item.type === 'count'">
          <dict-tag :options="capacity_unit" :value="countUnit" />
        </span>
      </div>
    </slot>
  </div>
</template>
<script setup name="StatisticInfo">
import { useStore } from 'vuex'
const store = useStore()
const { proxy } = getCurrentInstance()
const { capacity_unit } = proxy.useDict('capacity_unit')
const props = defineProps({
  info: {
    type: Object,
    default: () => { }
  }
})
const weightUnit = ref()
const countUnit = ref()
watch(
  () => store.state.variety.itemObj,
  (itemObj) => {
    if (itemObj) {
      countUnit.value = itemObj.goodsCountUnit
      weightUnit.value = itemObj.goodsWeightUnit
    }
  },
  {
    immediate: true,
  }
)
</script>
<style lang="scss" scoped>
.statistic {
  position: fixed;
  left: 220px;
  right: 0;
  bottom: 10px;
  z-index: 3;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
  height: 56px;
  font-size: 16px;
  color: rgba(0, 0, 0, .85);
  font-weight: 650;
  box-shadow: 6px 6px 12px 6px rgba(0, 0, 0, 0.08);
  background-color: #fff;

  &.statistic-static {
    position: static;
    justify-content: flex-end;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.08);
  }

  &-item {
    .count {
      color: #337FFF !important;
      font-size: 18px;
    }
  }
}
</style>
<template>
  <!-- 父页面引入 -->
  <!-- <AddCommodity v-model="modelValue" @submitForm="submitForm"></AddCommodity> -->
  <el-dialog :before-close="cancel" :close-on-click-modal="false" title="商品信息" :model-value="modelValue" width="80%"
    append-to-body>
    <el-form :model="queryParams" ref="queryRef" :inline="true" label-width="68px" class="custom-form">
      <el-form-item label="商品名称" prop="productName">
        <el-input v-model="queryParams.productName" placeholder="请输入商品名称" clearable />
      </el-form-item>
      <el-form-item label="商品编码" prop="productCode">
        <el-input v-model="queryParams.productCode" placeholder="请输入商品编码" clearable />
      </el-form-item>
      <el-form-item>
        <el-button @click="resetForm">清空</el-button>
        <el-button type="primary" @click="handleQuery">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table ref="singleTable" v-loading="loading" :data="productInfoList" highlight-current-row @row-click="rowClick">
      <el-table-column label="单选" width="50">
        <template #default="{ row }">
          <el-radio v-model="productCode" :label="row.productCode">
            <span></span>
          </el-radio>
        </template>
      </el-table-column>
      <el-table-column label="序号" type="index" width="50" />
      <el-table-column label="商品名称" prop="productName" />
      <el-table-column label="商品编码" prop="productCode" width="150" />
      <el-table-column :label="queryParams.varietyCode === 'SR' ? '榨季' : '年份'" prop="years" />
      <el-table-column label="等级" prop="gradeName" />
      <el-table-column label="产地" prop="placeOrgan" />
      <el-table-column label="品牌" prop="brandName" />
      <el-table-column label="包装单位" prop="countUnit">
        <template #default="scope">
          <dict-tag :options="capacity_unit" :value="scope.row.countUnit" />
        </template>
      </el-table-column>
      <el-table-column label="规格" prop="specificationVal">
        <template #default="{ row }">
          {{ row.specificationVal }}
          <dict-tag :options="capacity_unit" :value="row.weightUnit" />
          /
          <dict-tag :options="capacity_unit" :value="row.countUnit" />
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total > 0" :total="total" v-model:page="queryParams.pageNum" v-model:limit="queryParams.pageSize"
      @pagination="getList" />
    <template #footer>
      <div class="dialog-footer">
        <el-button class="gray-btn" @click="cancel">取 消</el-button>
        <el-button class="blue-btn" @click="submitForm">确 定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup lang='tsx'>
import { reactive, getCurrentInstance, toRefs, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { getProductInfoList } from '@/api/wms/components/index'
import { ElMessage } from 'element-plus'
const { proxy }: any = getCurrentInstance()
const store = useStore()
const emits = defineEmits(['update:modelValue', 'submitForm'])
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})
const { capacity_unit } = proxy.useDict('capacity_unit')
const data = reactive({
  total: 0,
  queryParams: {
    pageNum: 1,
    pageSize: 10,
    productName: null,
    productCode: null,
    varietyCode: store.state.variety.varietyId,
    status: '0'
  },
  loading: false,
  productInfoList: [],
  selected: null,
})
const { total, queryParams, loading, productInfoList, selected } = toRefs(data)
watch(
  () => store.state.variety.varietyId,
  (value) => {
    if (value) {
      queryParams.value.varietyCode = value
      getList()
    }
  },
  {
    immediate: true,
  }
)

// 搜索
function handleQuery() {
  queryParams.value.pageNum = 1
  getList()
}

// 重置
function resetForm() {
  proxy.resetForm('queryRef')
  handleQuery()
}

// 查询列表
function getList() {
  loading.value = true
  getProductInfoList(queryParams.value).then((response: any) => {
    productInfoList.value = response.rows
    total.value = response.total
    loading.value = false
  })
}
// 单选数据
const productCode = ref()
const singleTable = ref()
const rowClick = (val: any) => {
  singleTable.value.setCurrentRow(val)
  productCode.value = val.productCode
  selected.value = val
}

// 取消
function cancel() {
  emits('update:modelValue', false)
  proxy.$refs.singleTable.clearSelection()
  selected.value = null
  productCode.value = null
}

// 提交
function submitForm() {
  if (selected.value) {
    emits('submitForm', selected.value)
    cancel()
  } else {
    ElMessage({
      type: 'warning',
      message: '请选择商品'
    })
  }
}
</script>

<style scoped></style>

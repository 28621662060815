<template>
  <el-dialog :model-value="modelValue" title="选择磅单" width="80%" append-to-body @close="emits('cancel')">
    <el-form ref="formRef" :model="formModel" :inline="true" label-width="68px" class="custom-form">
      <el-form-item label="车牌号" prop="carPlateNum">
        <el-input v-model="formModel.carPlateNum" placeholder="请输入车牌号" />
      </el-form-item>
      <el-form-item label="">
        <el-button @click="resetCloudForm(formRef)">清空</el-button>
        <el-button type="primary" @click="getData">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table ref="cloudTableRef" :data="tableData" v-loading="loading" highlight-current-row @row-click="rowClick">
      <el-table-column width="50" fixed="left" label="单选">
        <template #default="{ row }">
          <el-radio v-model="wbCode" :label="row.wbCode">
            <span></span>
          </el-radio>
        </template>
      </el-table-column>
      <el-table-column type="index" width="55" label="序号" fixed="left" />
      <el-table-column label="磅单编号" width="150" prop="wbCode" fixed="left" />
      <el-table-column label="预约单号" width="150" prop="appointmentCode" />
      <el-table-column label="仓库名称" width="130" show-overflow-tooltip prop="whName" />
      <el-table-column label="车牌号" width="100" prop="carPlateNum" />
      <el-table-column label="业务类型" prop="wbOrderType">
        <template #default="scope">
          <dict-tag :options="wb_order_type" :value="scope.row.wbOrderType" />
        </template>
      </el-table-column>
      <el-table-column label="毛重" prop="grossWeight" />
      <el-table-column label="皮重" prop="tareWeight" />
      <el-table-column label="净重" prop="netWeight" />
      <el-table-column label="实际称重" prop="realWeight" />
      <el-table-column label="扣重" prop="deductWeight">
        <template #default="{ row }">
          <el-input v-model="row.deductWeight" placeholder="请输入" />
        </template>
      </el-table-column>
      <el-table-column label="计量单位" prop="weightUnit">
        <template #default="scope">
          <dict-tag :options="capacity_unit" :value="scope.row.weightUnit" />
        </template>
      </el-table-column>
      <el-table-column label="数量" prop="goodsCount">
        <template #default="scope">
          {{ scope.row.goodsCount }}
          <dict-tag :options="capacity_unit" :value="scope.row.countUnit" />
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="wbState">
        <template #default="scope">
          <dict-tag :options="wb_state" :value="scope.row.wbState" />
        </template>
      </el-table-column>
      <el-table-column label="接入类型" width="130" show-overflow-toolti prop="bridgeInType">
        <template #default="{ row }">
          <dict-tag :options="wb_bridge_in_type" :value="row.bridgeInType" />
        </template>
      </el-table-column>
      <el-table-column label="接入方式" width="130" show-overflow-toolti prop="weightBridgeType">
        <template #default="{ row }">
          <dict-tag :options="wb_mode" :value="row.weightBridgeType" />
        </template>
      </el-table-column>
      <el-table-column label="司机姓名" width="130" show-overflow-toolti prop="driverName" />
      <el-table-column label="司机身份证号" width="130" show-overflow-toolti prop="driverIdCard" />
      <el-table-column label="客户名称" width="130" show-overflow-toolti prop="companyName" />
      <el-table-column label="商品名称" width="130" show-overflow-tooltip prop="productName" />
      <el-table-column label="进场时间" prop="inTime" width="180">
        <template #default="scope">
          <span>{{ parseTime(scope.row.inTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="出场时间" prop="outTime" width="180">
        <template #default="scope">
          <span>{{ parseTime(scope.row.outTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="进场司磅员" prop="inUserName" width="100" />
      <el-table-column label="出场司磅员" prop="outUserName" width="100" />
      <el-table-column label="仓储员" prop="storekeeper" />
      <el-table-column label="装卸员" prop="loadName" />
      <el-table-column label="进场说明" prop="inExplain" />
      <el-table-column label="出场说明" prop="outExplain" width="200" show-overflow-tooltip>
        <template #default="{ row }">
          <CustomTooltip :text="row.outExplain" />
        </template>
      </el-table-column>
      <el-table-column label="单据编号" prop="billCode" />
      <el-table-column label="生产编号" prop="produceCode" />
      <el-table-column label="提货人" prop="consignee" />
      <el-table-column label="收货单位" prop="contactUser" />
      <el-table-column label="收货联系人" prop="whUser" width="100" />
      <el-table-column label="收货地址" prop="whAddress" />
      <el-table-column label="进场设备号" prop="inDeviceCode" width="120" />
      <el-table-column label="出场设备号" prop="outDeviceCode" width="120" />
      <el-table-column label="创建时间" prop="createTime" width="180">
        <template #default="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="cloud-footer">
      <el-pagination v-model:current-page="pageNum" :page-size="pageSize" small="small" :disabled="count === 0"
        layout="total, prev, pager, next, jumper" :total="count" @size-change="changeSize" @current-change="changePage" />
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="submit">确定</el-button>
        <el-button @click="emits('cancel')">取消</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup name="SelectWeightList">
import { onMounted, ref } from 'vue'
import { weighbridgeOwnList } from '@/api/wms/weighbridge/weighbridge'
import { ElMessage } from 'element-plus'
const { proxy } = getCurrentInstance()
const { capacity_unit, wb_bridge_in_type, wb_order_type, wb_state, wb_mode } = proxy.useDict('capacity_unit', 'wb_order_type', 'wb_bridge_in_type', 'wb_state', 'wb_mode')
const props = defineProps({
  // 显示状态
  modelValue: {
    type: Boolean,
    default: false,
  },
  wbOrderType: {
    type: String,
    default: 'GOODS_IN'
  },
  carPlateNum: {
    type: String,
    default: null
  },
  wbCode: {
    type: String,
    default: null
  },
})
const emits = defineEmits(["update:modelValue", "submit", "cancel"])
const loading = ref(false)
const wbCode = ref()
const row = ref()
const formRef = ref()
const formModel = ref({
  wbOrderType: null,
  carPlateNum: null,
})
const pageNum = ref(1)
const pageSize = ref(10)
const count = ref(0)
const tableData = ref([])
const submit = () => {
  if (!row.value) {
    row.value = tableData.value.find(row => row.wbCode === wbCode.value)
  }
  if (!row.value) {
    ElMessage({
      type: 'warning',
      message: '请选择榜单'
    })
    return
  }
  console.log(row.value)
  emits("submit", row.value)
}
const getData = async () => {
  loading.value = true
  const { code, rows, total } = await weighbridgeOwnList({
    ...formModel.value,
    pageNum: pageNum.value,
    pageSize: pageSize.value,
    wbCode: wbCode.value
  })
  if (code === 200) {
    tableData.value = rows
    count.value = total
    loading.value = false
  } else {
    loading.value = false
  }
}
const resetCloudForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
}
const changePage = (val) => {
  pageNum.value = val
  getData()
}
const changeSize = (val) => {
  pageSize.value = val
  getData()
}

// 单选
const cloudTableRef = ref()
const rowClick = (val) => {
  wbCode.value = val.wbCode
  row.value = val
  console.log(row.value)
}

watch(
  () => props,
  (val) => {
    if (val) {
      formModel.value.carPlateNum = val.carPlateNum
      formModel.value.wbOrderType = val.wbOrderType
      wbCode.value = val.wbCode
      getData()
    }
  },
  { immediate: true, deep: true }
)

defineExpose({
  wbCode,
  row
})
</script>
<style lang="scss" scoped>
.cloud-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}
</style>
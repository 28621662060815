<template>
  <el-dialog v-model="visible" :title="title" width="950px" :before-close="onClose">
    <div class="container">
      <div class="medias">
        <div class="img-card" v-for="item in imgList" :key="item">
          <ImageCard v-if="!item.isVideo" :images="item.urls" :title="item.title"></ImageCard>
          <VideoCard v-else-if="isVideo" :videos="item.urls" :title="item.title"></VideoCard>
        </div>
      </div>
    </div>
    <template #footer v-if="data && data.operations && data.operations.length > 1">
      <div class="buttons">
        <el-button :icon="ArrowLeftBold" :disabled="currentIdx <= 0" class="orange-btn" @click="previous">
          上一个垛位
        </el-button>
        <el-select v-model="currentIdx" style="margin: 0 20px" @change="onStackChange">
          <el-option v-for="item in stacks" :key="item.value" :value="item.value" :label="item.label" />
        </el-select>
        <el-button :disabled="currentIdx >= data.operations.length - 1" class="blue-btn" @click="next">
          下一个垛位<el-icon style="margin-left: 6px">
            <ArrowRightBold />
          </el-icon>
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, watch } from 'vue'
import ImageCard from './ImageCard.vue'
import VideoCard from './VideoCard.vue'
import { getFileUrl } from '@/api/wms/file'
import { ArrowLeftBold, ArrowRightBold } from '@element-plus/icons-vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: '物联网存证'
  },
  /**
   *@type {{operations:{images:{title:String,url:string,needTransfer:boolean}[],stackName:string}[],carPics:{title:String,url:string,needTransfer:boolean}[]}}
   */
  data: {
    type: Object,
    required: true,
    default: {}
  }
})

const emit = defineEmits(['update:modelValue'])

const visible = ref(props.modelValue)

const imgList = ref([])
const isVideo = ref(true)

function onClose() {
  visible.value = false
  emit('update:modelValue', visible.value)
}

async function getPictures(url) {
  if (!url) return []
  const urls = url.split(',')
  return await Promise.all(
    urls.map(async (it) => {
      const res = await getFileUrl(it)
      return res.data
    })
  )
}

const carPics = ref([])
const operations = ref([])
const currentIdx = ref(0)
const stacks = ref([])

function onStackChange() {
  transferOperation()
}

function previous() {
  if (currentIdx.value <= 0) return
  currentIdx.value--
  transferOperation()
}

function next() {
  if (currentIdx.value >= props.data.operations.length - 1) return
  currentIdx.value++
  transferOperation()
}

async function transferOperation() {
  const idx = currentIdx.value
  if (!operations.value[idx]) {
    operations.value[idx] = await Promise.all(
      props.data.operations[idx].images.map(async (item) => {
        {
          let urls
          if (!item.isVideo && item.needTransfer) {
            urls = await getPictures(item.url)
          } else {
            urls = item.url ? item.url.split(',') : []
          }

          return {
            urls,
            title: item.title,
            isVideo: item.isVideo
          }
        }
      })
    )
  }
  imgList.value = operations.value[idx]

  // 解决视频不更新问题
  isVideo.value = false
  setTimeout(() => {
    isVideo.value = true
  }, 10)
}
async function updateData(value) {
  operations.value = []
  currentIdx.value = 0
  stacks.value = []
  carPics.value = []
  imgList.value = []

  if (!value || !value.operations) {
    return
  }
  stacks.value = value.operations.map((item, idx) => {
    return {
      value: idx,
      label: item.stackName
    }
  })
  // carPics.value = await Promise.all(
  //   value.carPics.map(async (item) => {
  //     return {
  //       title: item.title,
  //       urls: await getPictures(item.url)
  //     }
  //   })
  // )
  transferOperation()
}

watch(
  () => props.modelValue,
  (value) => {
    if (visible.value !== value) visible.value = value
  }
)

watch(
  () => props.data,
  (value) => {
    console.log('data', 1111111111)
    updateData(value)
  }
)

onMounted(() => {
  updateData(props.data)
})
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  color: #666;
  font-size: 14px;

  .medias {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    justify-content: flex-start;

    .img-card {
      position: relative;
      width: 210px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}

.buttons {
  display: flex;
  justify-content: center;
}
</style>

<template>
  <div class="card">
    <div class="title">{{ title }}</div>
    <div class="body">
      <div class="image-container">
        <template v-if="images.length > 0">
          <div class="image-wrapper">
            <el-image v-if="currentImageUrl" class="image" :src="currentImageUrl" :preview-src-list="images"
              fit="fill"></el-image>
          </div>
          <div class="left-control" v-if="currentIdx > 0" @click="previous">
            <el-icon>
              <ArrowLeftBold />
            </el-icon>
          </div>
          <div class="right-control" v-if="currentIdx < images.length - 1" @click="next">
            <el-icon>
              <ArrowRightBold />
            </el-icon>
          </div>
          <span class="bottom-class">第{{ currentIdx + 1 }}张/共{{ images.length }}张</span>
        </template>
        <el-empty :image-size="100" description="暂无抓拍" v-else></el-empty>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { ArrowLeftBold, ArrowRightBold } from '@element-plus/icons-vue'
const props = defineProps({
  images: {
    type: Array,
    default: () => []
  },
  title: {
    type: String,
    default: '监控抓拍'
  }
})

const currentIdx = ref(0)
const currentImageUrl = ref('')

function previous() {
  currentIdx.value--
  setImage()
}

function next() {
  currentIdx.value++
  setImage()
}

function setImage() {
  currentImageUrl.value = props.images[currentIdx.value]
}

watch(
  () => props.images,
  (value) => {
    if (value.length > 0) {
      currentIdx.value = 0
      setImage()
    }
  }
)

onMounted(() => {
  if (props.images.length > 0) {
    setImage()
  }
})
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  width: 100%;
  // height: 240px;
  height: 167px;

  .title {
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    width: 100%;
    height: 38px;
    box-sizing: border-box;
    padding: 0 0 0 10px;
    line-height: 38px;
    border-radius: 8px 8px 0 0;
    z-index: 1;
  }

  .body {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.5);

    .image-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .image-wrapper {
        position: relative;
        width: calc(100% - 40px);
        height: 100%;
        margin-top: 36px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        .image {
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;
        }

        .el-image {
          border-radius: 0 0 7px 7px;
          height: 130px;
        }
      }

      .left-control {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        font-size: 20px;
      }

      .right-control {
        position: absolute;
        right: 0;
        top: 50%;
        cursor: pointer;
        transform: translateY(-50%);
        font-size: 20px;
      }

      .bottom-class {
        position: absolute;
        bottom: -30px;
        left: 30%;
      }

      &:hover {

        .left-control,
        .right-control {
          display: block;
        }
      }
    }
  }
}
</style>

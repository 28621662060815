import request from '@/utils/request'

// 查询垛位管理列表，删除的不查询
export function listStack(query) {
  return request({
    url: '/wms/stack/listCapacity',
    method: 'get',
    params: query
  })
}

// 查询垛位接口
export function listCapacityAll(query) {
  return request({
    url: '/wms/stack/listCapacityAll',
    method: 'get',
    params: query
  })
}

// 查询垛位管理列表，删除的也查询
export function listTabulation(query) {
  return request({
    url: '/wms/stack/listTabulation',
    method: 'get',
    params: query
  })
}

// 查询二维码
export function getQRCode(id) {
  return request({
    url: '/wms/stack/QRCode/' + id,
    method: 'get'
  })
}

// 查询垛位管理详细
export function getStack(id) {
  return request({
    url: '/wms/stack/' + id,
    method: 'get'
  })
}

// 新增垛位管理
export function addStack(data) {
  return request({
    url: '/wms/stack',
    method: 'post',
    data: data
  })
}

// 修改垛位管理
export function updateStack(data) {
  return request({
    url: '/wms/stack',
    method: 'put',
    data: data
  })
}

// 删除垛位管理
export function delStack(id) {
  return request({
    url: '/wms/stack/' + id,
    method: 'delete'
  })
}

export async function searchStacks(params) {
  const data = await request({
    url: '/wms/stack/listByFuzzName',
    method: 'get',
    params: params
  })
  return data.data || []
}

// 查询跺位完整编码
export function getFullCode(id) {
  return request({
    url: '/wms/stack/full/' + id,
    method: 'get'
  })
}

// 查询跺位完整编码
export async function searchStacksTwo(params) {
  const data = await request({
    url: '/wms/stack/listByFuzzNameTwo',
    method: 'get',
    params: params
  })
  return data.data || []
}

export async function getGoodsInfoByStackId(stackId) {
  return request({
    url: '/wms/stack/getInStackInfoByStackId/' + stackId
  })
}
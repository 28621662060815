<template>
  <div class="datav-footer-menu">
    <img src="@/assets/datav/bottom-left.png" class="datav-footer-left" />
    <div class="menu-class">
      <div class="menu-item" v-for="(item, i) in children" :key="i" @click="clickHander(item, i)">
        <span>{{ item.meta.title }}</span>
      </div>
    </div>
    <img src="@/assets/datav/bottom-right.png" class="datav-footer-right" />
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
const store = useStore()
const children = computed(() => {
  console.log(store.getters.sidebarRouters)
  let large = store.getters.sidebarRouters.find((item) => item.name?.indexOf('Largescreen') >= 0)
  let menu = store.getters.sidebarRouters.filter(item => item.meta?.title && (item.meta?.title === '仓储管理'
    || item.meta?.title === '注册仓单' || item.meta?.title === '巡更管理'))
  console.log(menu)
  if (large?.children) {
    console.log(large.children.concat(menu))
    return large.children.concat(menu)
  } else {
    return menu
  }
}
)
function clickHander(item, i) {
  console.log(item, i)
  localStorage.setItem('activeName', i + 2)
  router.push({ path: item.fullPath })
}
</script>
<template>
  <el-table :data="tableData" :border="border">
    <el-table-column type="index" width="55" label="序号" />
    <el-table-column prop="cloudCode" v-if="spotTransfer || isShowCode" label="云仓单号" width="160" />
    <el-table-column prop="roomName" label="库房" show-overflow-tooltip min-width="120" />
    <el-table-column prop="stackName" label="垛位" show-overflow-tooltip min-width="120" />
    <el-table-column prop="companyName" v-if="spotTransfer" label="转让方" show-overflow-tooltip min-width="120" />
    <el-table-column prop="productName" v-if="spotTransfer || isShowProduct" :label="varietyId === 'CF' ? '批次号' : '商品名称'"
      show-overflow-tooltip min-width="120" />
    <el-table-column v-if="visible" prop="availableCount,availableWeight" label="可用库存" width="170">
      <template #default="scope">
        {{ scope.row.availableWeight }}
        <dict-tag :options="capacity_unit" :value="scope.row.weightUnit" />
        /
        {{ scope.row.availableCount }}
        <dict-tag :options="capacity_unit" :value="scope.row.countUnit" />
      </template>
    </el-table-column>
    <el-table-column prop="availableCount,availableWeight" label="可用张数"
      v-if="(isCancel || isReceipt) && scene == 'add' && varietyId !== 'CF'">
      <template #default="scope">
        {{ parseInt(scope.row.availableWeight / futuresLotTon) }}张
      </template>
    </el-table-column>
    <el-table-column prop="receiptCount" :label="isCancel ? '注销张数' : '注册张数'" width="150" v-if="isCancel || isReceipt">
      <template #default="scope">
        <el-input v-model="scope.row.receiptCount" placeholder="请输入张数" clearable @input="getWeightCount(scope.row)"
          v-if="scene == 'add'" />
        <div v-else>
          {{ scope.row.receiptCount }}
        </div>
      </template>
    </el-table-column>
    <el-table-column v-if="storageBasis === '2'" prop="weight" label="重量" :width="scene !== 'add' ? '100' : '200'">
      <template #default="scope">
        <div v-if="scene !== 'add'">
          {{ scope.row.weight }}
          <dict-tag :options="capacity_unit" :value="scope.row.weightUnit" />
        </div>
        <div v-else>
          <el-input-number v-model="scope.row.weight" placeholder="请输入重量" :min="0"
            :max="scope.row.availableWeight ? scope.row.availableWeight : scope.row.weight" clearable
            style="width: 120px; margin-right: 5px;" @change="calculate(scope.row)"
            :disabled="varietyId !== 'CF' && isCancel && registerMethod !== '1'" />
          <dict-tag :options="capacity_unit" :value="scope.row.weightUnit" />
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="count" v-if="storageBasis === '2'" label="数量" :width="scene !== 'add' ? '100' : '200'">
      <template #default="scope">
        <div v-if="scene !== 'add'">
          {{ scope.row.count }}
          <dict-tag :options="capacity_unit" :value="scope.row.countUnit" />
        </div>
        <div v-else>
          <el-input-number v-model="scope.row.count" :min="0"
            :max="scope.row.availableCount ? scope.row.availableCount : scope.row.count" placeholder="请输入数量"
            @change="calculateNew(scope.row)" style="width: 120px; margin-right: 5px;" clearable
            :disabled="varietyId !== 'CF' && isCancel && registerMethod !== '0'" />
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="count" v-if="storageBasis === '1'" label="数量" :width="scene !== 'add' ? '100' : '200'">
      <template #default="scope">
        <div v-if="scene !== 'add'">
          {{ scope.row.count }}
          <dict-tag :options="capacity_unit" :value="scope.row.countUnit" />
        </div>
        <div v-else>
          <el-input-number v-model="scope.row.count" :min="0"
            :max="scope.row.availableCount ? scope.row.availableCount : scope.row.count" placeholder="请输入注销数量"
            style="width: 120px; margin-right: 5px;" clearable @change="calculate(scope.row)"
            :disabled="varietyId !== 'CF' && isCancel" />
          <dict-tag :options="capacity_unit" :value="scope.row.countUnit" />
        </div>
      </template>
    </el-table-column>
    <el-table-column v-if="storageBasis === '1'" prop="weight" label="重量" width="200">
      <template #default="scope">
        <div v-if="scene !== 'add'">
          {{ scope.row.weight }}
          <dict-tag :options="capacity_unit" :value="scope.row.weightUnit" />
        </div>
        <div v-else>
          <el-input-number v-model="scope.row.weight" :min="0"
            :max="scope.row.availableWeight ? scope.row.availableWeight : scope.row.weight" placeholder="请输入注销重量"
            clearable style="width: 120px; margin-right: 5px;" :disabled="varietyId !== 'CF' && isCancel" />
          <dict-tag :options="capacity_unit" :value="scope.row.weightUnit" />
        </div>
      </template>
    </el-table-column>
    <!--    <el-table-column label="按数量出清云仓单" width="130" v-if="isShowFirm">-->
    <!--      <template #default="scope">-->
    <!--        <el-switch v-model="scope.row.isShow" active-text="打开">-->
    <!--        </el-switch>-->
    <!--      </template>-->
    <!--    </el-table-column>-->
    <el-table-column v-if="isShowNewStack" prop="roomNewId" label="移入库房" width="150">
      <template #default="scope">
        <el-select @click.native="getRooms(scope.row)" @change="getStack(scope.row)" clearable
          v-model="scope.row.roomNewId" placeholder="请选择库房" style="width: 120px;" filterable>
          <el-option v-for="item in scope.row.newRoomList" :key="item.id" :label="item.roomName"
            :value="item.id"></el-option>
        </el-select>
      </template>
    </el-table-column>
    <el-table-column v-if="isShowNewStack" prop="stackNewId" label="移入垛位" width="150">
      <template #default="scope">
        <el-select clearable v-model="scope.row.stackNewId" placeholder="请选择垛位" style="width: 120px; margin-left: 10px"
          filterable>
          <el-option v-for="item in (scope.row.newRoom ? scope.row.newRoom.whStacks : null)" :key="item.id"
            :label="`${item.stackName}-${item.productName ? item.productName : '空垛'}`" :value="item.id"></el-option>
        </el-select>
      </template>
    </el-table-column>
    <el-table-column prop="inventoryFile" label="货权凭证" :width="'115px'" v-if="isMove">
      <template #default="scope">
        <ShowFiles :files="scope.row.inventoryFile" />
      </template>
    </el-table-column>
    <el-table-column prop="qualityFile" label="质检证书" :width="'115px'" v-if="isMove">
      <template #default="scope">
        <el-link type="primary" @click="showCarFile(scope.row, 'qualityFile')">{{
          scope.row.qualityFile ? '查看' : '上传' }}</el-link>
      </template>
    </el-table-column>
    <el-table-column v-if="carVisible" prop="carPlateNum" label="车牌号" width="120">
      <template #default="{ row, $index }">
        <div v-if="scene === 'detail'">
          {{ row.carPlateNum }}
        </div>
        <div v-else>
          <!-- <el-form-item :prop="`tableData[${$index}].carPlateNum`" :rules="rules.carPlateNum"> -->
          <el-input v-model="row.carPlateNum" placeholder="请输入" @blur="handlerBlur(row.carPlateNum, 'car')">
            <template v-if="row.carPlateNum && scene === 'add'" #append>
              <el-button @click="relevanceWB(row)">关联磅单</el-button>
            </template>
          </el-input>
          <!-- </el-form-item> -->
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="vehicleVoucher" label="车辆附件" width="150px" v-if="carVisible">
      <template #default="{ row, $index }">
        <el-link type="primary" @click="showCarFile(row)" style="margin-right: 20px;">{{
          row.vehicleVoucher ? '查看'
          : '上传' }}</el-link>
        <el-link type="primary" @click="showQrcode(row)">手机上传</el-link>
      </template>
    </el-table-column>
    <el-table-column label="物联网" prop="things" width="100" v-if="carVisible">
      <template #default="{ row, $index }">
        <el-button type="text" @click="showNetwork(row)">查看</el-button>
      </template>
    </el-table-column>
    <el-table-column v-if="carVisible" prop="truckTime"
      :label="parentData.wbOrderType === 'GOODS_OUT' ? '车辆出库时间' : '车辆入库时间'" width="160">
      <template #default="{ row, $index }">
        {{ row.truckTime }}
      </template>
    </el-table-column>
    <el-table-column v-if="carVisible" prop="wbCode" label="磅单号" width="150" />
    <el-table-column v-if="carVisible" prop="driverName" label="司机名称" width="100">
      <template #default="{ row, $index }">
        <div v-if="scene === 'detail'">
          {{ row.driverName }}
        </div>
        <div v-else>
          <!-- <el-form-item :prop="`tableData[${$index}].driverName`" :rules="rules.driverName"> -->
          <el-input v-model="row.driverName" placeholder="请输入" @blur="handlerBlur(row.carPlateNum, 'name')" />
          <!-- </el-form-item> -->
        </div>
      </template>
    </el-table-column>
    <el-table-column v-if="carVisible" prop="driverPhone" label="司机电话" width="120">
      <template #default="{ row, $index }">
        <div v-if="scene === 'detail'">
          {{ row.driverPhone }}
        </div>
        <div v-else>
          <!-- <el-form-item :prop="`tableData[${$index}].driverPhone`" :rules="rules.driverPhone"> -->
          <el-input v-model="row.driverPhone" placeholder="请输入" />
          <!-- </el-form-item> -->
        </div>
      </template>
    </el-table-column>
    <el-table-column v-if="carVisible" prop="idCard" label="身份证号" width="150" show-overflow-tooltip>
      <template #default="{ row, $index }">
        <div v-if="scene === 'detail'">
          {{ row.idCard }}
        </div>
        <div v-else>
          <!-- <el-form-item :prop="`tableData[${$index}].idCard`" :rules="rules.idCard"> -->
          <el-input v-model="row.idCard" placeholder="请输入" />
          <!-- </el-form-item> -->
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="remark" label="备注" width="150" v-if="isShowRemark">
      <template #default="{ row, $index }">
        <div v-if="scene === 'detail'">
          {{ row.remark }}
        </div>
        <div v-else>
          <!-- <el-form-item :prop="`tableData[${$index}].remark`"> -->
          <el-input v-model="row.remark" placeholder="请输入备注" />
          <!-- </el-form-item> -->
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="cloudCode" label="涉及云仓单" width="120"
      v-if="parentData.outState === '5' || parentData.outState === '4' || parentData.outState === '7'">
      <template #default="{ row }">
        <el-link type="primary" @click="getCloudCode(row)">详情</el-link>
      </template>
    </el-table-column>
    <el-table-column v-if="scene === 'add'" label="操作" fixed="right">
      <template #default="scope">
        <el-link type="primary" @click="removeRow(scope.$index, scope.row)">移除</el-link>
        <!--        <el-button type="text" v-if="isShowFirm" @click="takeStock(scope.row)">库存盘点</el-button>-->
      </template>
    </el-table-column>
  </el-table>
  <div class="statistic">
    <div class="statistic-item">合计：</div>
    <div v-if="storageBasis === '2'" class="statistic-item">
      总重量：<span class="count">{{ totalWeight.toFixed(4) }}</span>
      <dict-tag :options="capacity_unit" :value="weightUnit" />
    </div>
    <div v-if="storageBasis === '2'" class="statistic-item">
      总数量：<span class="count">{{ totalCount.toFixed(4) }}</span>
      <dict-tag :options="capacity_unit" :value="countUnit" />
    </div>
    <div v-if="storageBasis === '1'" class="statistic-item">
      总重量：<span class="count">{{ totalWeight.toFixed(4) }}</span>
      <dict-tag :options="capacity_unit" :value="weightUnit" />
    </div>
    <div v-if="storageBasis === '1'" class="statistic-item">
      总数量：<span class="count">{{ totalCount.toFixed(4) }}</span>
      <dict-tag :options="capacity_unit" :value="countUnit" />
    </div>
  </div>
  <SelectWeightList v-model="weightVisible" :wbOrderType="parentData.wbOrderType ? parentData.wbOrderType : 'GOODS_IN'"
    :carPlateNum="selectItem.carPlateNum" :wbCode="selectItem.wbCode" @submit="sureSelectCloud"
    @cancel="weightVisible = false" />
  <el-dialog v-model="cloudCodeVisible" title="涉及云仓单" width="60%" append-to-body>
    <el-table :data="cloudCodeData" :border="border">
      <el-table-column prop="cloudCode" label="云仓单号" width="160" />
      <el-table-column prop="roomName" label="库房" show-overflow-tooltip />
      <el-table-column prop="stackName" label="垛位" show-overflow-tooltip />
      <el-table-column prop="totalCount,totalWeight" label="出库量" width="170">
        <template #default="scope">
          {{ scope.row.totalWeight }}
          <dict-tag :options="capacity_unit" :value="scope.row.weightUnit" />
          /
          {{ scope.row.totalCount }}
          <dict-tag :options="capacity_unit" :value="scope.row.countUnit" />
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
  <OperationDetailsDialog v-model="thingsDialogVisible" :data="selectItem.thingsInfo" />
  <car-file v-model:isShow="isShowCarFile" :vehicleVoucher="vehicleVoucher" :truckId="truckId"
    :orderType="parentData.wbOrderType === 'GOODS_OUT' ? '2' : '1'" @changeVv="changeVehicleVoucher"
    flag="updateTruckFilePc" :isSaveFile="scene !== 'add'" :field="field"></car-file>
  <!-- 生成二维码 -->
  <el-dialog :title="`请用微信扫描二维码上传凭证(ID:${selectItem ? selectItem.id : ''})`" v-model="updateCodeShow" width="400px"
    @close="getWXUploadFiles(selectItem.id)" append-to-body>
    <div style=" display: flex;flex-direction: column;align-items: center;">
      <qrcode-vue :value="qrcodeData" :size="250"></qrcode-vue>
      <div style="padding-top: 20px;">请上传完毕再关闭此页面</div>
    </div>
  </el-dialog>
  <!--  <FirmOffer @confirm-and-refresh="closeChildComponentAndRefreshList()" v-if="stockVisible" v-model="stockData" :stockVisible="stockVisible" :stockData="stockData"   />-->
</template>
<script setup name="StackList">
import { watch, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useFormItem, ElMessage, ElMessageBox } from 'element-plus'
import SelectWeightList from '@/components/SelectWeightList/index.vue'
import { roomListByEmptyStack, listAllStackNew, listAllNewByStack } from "@/api/wms/house/house"
import { getCloudCodeByAppCode } from '@/api/wms/iomanager/outOrder'
import { listPlateNosForDate, selectCloudByCompanyProductStack } from '@/api/wms/appointment/inAppointment'
import { getTruckFile } from '@/api/wms/appointment/outAppointment'
import OperationDetailsDialog from '@/components/OperationDetailsDialog/ImagesDialog.vue'
import carFile from '@/views/wms/order/components/carFile.vue'
import QrcodeVue from "qrcode.vue"
import { getThings } from '@/api/wms/appointment/inAppointment'
import FirmOffer from "@/components/StackList/firmOffer.vue";
import { getQrCodeUrl } from '@/utils/index'
const store = useStore()
const { form, formItem } = useFormItem()
const { proxy } = getCurrentInstance()
const { capacity_unit } = proxy.useDict('capacity_unit')
const props = defineProps({
  scene: {
    type: String,
    default: 'add'
  },
  data: {
    type: Array,
    default: () => {
      return []
    }
  },
  border: {
    type: Boolean,
    default: false
  },
  visible: { // 可用库存是否显示
    type: Boolean,
    default: true
  },
  spotTransfer: { // 可用库存是否显示
    type: Boolean,
    default: false
  },
  isShowCode: {//单独显示云仓单
    type: Boolean,
    default: false
  },
  isShowFirm: {
    type: Boolean,
    default: false
  },
  carVisible: {
    type: Boolean,
    default: false
  },
  count: {
    type: String,
    default: 'totalCount'
  },
  weight: {
    type: String,
    default: 'totalWeight'
  },
  parentData: {
    type: Object,
    default: {}
  },
  isShowNewStack: {
    type: Boolean,
    default: false
  },
  isShowProduct: {
    type: Boolean,
    default: false
  },
  isShowRemark: {
    type: Boolean,
    default: true
  },
  isCancel: {
    type: Boolean,
    default: false
  },
  isMove: {
    type: Boolean,
    default: false
  },
  isReceipt: {
    type: Boolean,
    default: false
  },
  registerMethod: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['removeIndex'])
const totalCount = ref(0)
const totalWeight = ref(0)
const storageBasis = ref()
const weightUnit = ref()
const varietyId = ref()
const countUnit = ref()
const tableData = ref([])
const cloudCodeData = ref([])
const removeRow = (index, row) => {
  tableData.value.splice(index, 1)
  emit('removeIndex', index, row)
}
const getWeightCount = (row) => {
  let { receiptCount, specificationVal } = row
  receiptCount = Number(receiptCount).toFixed();
  if (isNaN(receiptCount)) {
    ElMessage({
      message: '请输入整数',
      type: 'warning'
    })
    row.receiptCount = ''
    return
  }
  row.receiptCount = receiptCount
  row.weight = futuresLotTon.value * receiptCount
  row.count = (futuresLotTon.value * receiptCount / specificationVal).toFixed(4) * 1
}
// 根据 storageBasis 计算数量和重量
const calculate = (row) => {
  const { weight, count, specificationVal } = row
  if (storageBasis.value === '2') {
    row.count = (weight / specificationVal).toFixed(2) * 1
  } else {
    row.weight = (count * specificationVal).toFixed(2) * 1
  }
}

function multiply(a, b) {
  a = Number(a)
  b = Number(b)
  console.log(a, b)
  let alen = Number.isInteger(a) ? 0 : String(a).split(".")[1].length;
  let blen = Number.isInteger(b) ? 0 : String(b).split(".")[1].length;
  console.log(alen, blen)
  if (alen >= 0 && blen >= 0) {
    return (a * b).toFixed(alen + blen) //转化为已经知道的小数点长度的小数
  } else {
    return 0
  }
}
const calculateNew = (row) => {
  const { weight, count, specificationVal } = row
  console.log("row" + row)
  console.log("totalWeight" + weight)
  console.log("totalCount" + count)
  console.log("specificationVal" + specificationVal)
  if (count * specificationVal !== weight) {
    const message = '您输入的数量不等于重量/规格，请核查！';
    const confirmButtonText = '知道了';
    const type = 'warning';
    const showCancelButton = false;
    ElMessageBox.confirm(`${message}`, '提示', {
      confirmButtonText,
      type,
      showCancelButton,
    });
  }
}
const summation = () => {
  totalCount.value = 0
  totalWeight.value = 0
  tableData.value.forEach(item => {
    totalCount.value += item?.count ? item.count * 1 : 0
    totalWeight.value += item?.weight ? item.weight * 1 : 0
  })
}

const handlerBlur = (value, flag) => {
  const reg = {
    car: {
      pattern: /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/,
      message: '请输入正确的车牌号'
    },
    name: {
      pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/,
      message: '请输入司机姓名'
    },
    phone: {
      pattern: /^1[356789]\d{9}$/,
      message: '请输入正确的手机号'
    },
    id: {
      pattern: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
      message: '请输入正确的身份证号'
    }
  }
  if (!reg[flag].pattern.test(value)) {
    ElMessage({
      type: 'warning',
      message: reg[flag].message
    })
  }
}

const getRooms = async (value) => {
  if (!value.newRoom) {
    const { code, data } = await listAllNewByStack({
      tenantId: store.getters.tenantId, varietyCode: store.state.variety.varietyId,
      productCode: value.productCode, gradeName: value.gradeName
    })
    if (code === 200) {
      console.log(data)
      value.newRoomList = data.filter(item => { return item.whStacks && item.whStacks.length > 0 })
      if (value.roomNewId) {
        value.newRoom = value.newRoomList.find(item => item.id === value.roomNewId)
      }
    }
  }
}
const newStackList = ref([])
const newRoomList = ref([])
const getStack = async (value) => {
  if (value.roomNewId) {
    value.newRoom = value.newRoomList.find((item) => {
      return item.id == value.roomNewId
    })
    value.newRoom.whStacks = value.newRoom.whStacks.filter(item => item.id !== value.stackId)
    console.log(value.newRoom)
  }
  value.stackNewId = null//修改库房清空垛位
}
const getCloudCode = async (row) => {
  cloudCodeVisible.value = true
  const { code, data } = await getCloudCodeByAppCode({
    stackCode: row.stackCode,
    roomCode: row.roomCode,
    appointmentCode: props.parentData.appointmentCode
  })
  if (code === 200) {
    console.log(data)
    cloudCodeData.value = data
  }
}
const isShowCarFile = ref(false)
const vehicleVoucher = ref('')
const truckId = ref()
const selectItem = ref({})
const updateCodeShow = ref(false)
const qrcodeData = ref()
const field = ref()
const showCarFile = (item, arm = 'vehicleVoucher') => {
  selectItem.value = item
  isShowCarFile.value = true
  field.value = arm
  vehicleVoucher.value = item[field.value]
  truckId.value = item.id
}
const changeVehicleVoucher = (data) => {
  selectItem.value[field.value] = data
}
const stockVisible = ref(false)
const stockData = ref([])
// const closeChildComponentAndRefreshList = () => {
//   stockVisible.value = false;
//   // selectCloud()
// };

// const takeStock = ( row,) => {
//   console.log("row" + row)
//   const data = {
//     cloudCode: row.cloudCode,
//     productCode: row.productCode,
//     companyCode: row.companyCode,
//     roomId: row.roomId,
//     stackId: row.stackId,
//   };
//   console.log("data", data);
//   const { code, rows } =  selectCloudByCompanyProductStack(data)
//       .then(response => {
//         const { code, rows } = response;
//         console.log("code", code);
//         if (code === 200) {
//           stockVisible.value = true
//           stockData.value = rows
//         }
//       })
// }

//关闭二维码页面时获取最新的凭证
const getWXUploadFiles = async (apmId) => {
  const { code, data } = await getTruckFile(apmId + '/' + (props.parentData.wbOrderType !== 'GOODS_OUT' ? '1' : '2'))
  if (code === 200) {
    selectItem.value.vehicleVoucher = data
  }
}
const showQrcode = (row) => {
  updateCodeShow.value = true
  selectItem.value = row
  qrcodeData.value = getQrCodeUrl(row.id, props.parentData.wbOrderType !== 'GOODS_OUT' ? '1' : '2')
}
// 物联网信息
const thingsDialogVisible = ref(false)
const showNetwork = async (row) => {
  selectItem.value = row
  const { code, msg } = await getThings({ admissionDate: row.truckTime || row.createTime, plateNo: row.carPlateNum })
  if (code === 200) {
    row.thingsInfo = {
      operations: [{
        images: [{
          title: `${row.carPlateNum}抓拍`,
          needTransfer: true,
          url: msg,
          isVideo: false
        }],
      }]
    }
  }
  if (selectItem.value.thingsInfo) {
    thingsDialogVisible.value = true
  } else {
    ElMessage({
      type: 'warning',
      message: '暂无信息'
    })
  }
}
watch(
  () => tableData,
  (val) => {
    if (val.value.length > 0) {
      summation()
    }
  },
  { deep: true, immediate: true }
)

watch(
  () => props.data,
  (val) => {
    tableData.value = val
    tableData.value.newRoomList = null
  },
  {
    immediate: true,
    deep: true
  }
)
const futuresLotTon = ref()
watch(
  () => store.state.variety.itemObj,
  (itemObj) => {
    if (itemObj) {
      storageBasis.value = itemObj.storageBasis
      weightUnit.value = itemObj.goodsWeightUnit
      countUnit.value = itemObj.goodsCountUnit
      futuresLotTon.value = itemObj.futuresLotTon
    }
  },
  {
    immediate: true,
  }
)
watch(
  () => store.state.variety,
  (itemObj) => {
    if (itemObj) {
      varietyId.value = itemObj.varietyId;
    }
  },
  {
    immediate: true,
  }
)

onMounted(() => {
  console.log(store.getters.tenantId, 'form')
  if (props.isShowNewStack && tableData.value && tableData.value.length === 1) {
    getRooms(tableData.value[0])
  }
})
defineExpose({
  tableData
})
const weightVisible = ref(false)
const cloudCodeVisible = ref(false)
const relevanceWB = async (row) => {
  selectItem.value = row
  weightVisible.value = true
}
const sureSelectCloud = (row) => {
  // 毛重: grossWeight、皮重:tareWeight 扣重: deductWeight 毛重-皮重-扣重=实际重量
  const { grossWeight, tareWeight, deductWeight, wbCode } = row
  const weight = grossWeight - (tareWeight || 0) - (deductWeight || 0)
  selectItem.weight = weight
  selectItem.wbCode = wbCode
  weightVisible.value = false
  calculate(currentIndex.value, selectItem)
}

</script>
<style lang="scss" scoped>
.statistic {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  padding: 0 20px;
  height: 45px;
  font-size: 16px;
  color: rgba(0, 0, 0, .85);
  font-weight: 650;
  //   box-shadow: 6px 6px 12px 6px rgba(0,0,0,0.08);
  background-color: #fff;

  &-item {
    .count {
      color: #337FFF;
      font-size: 18px;
    }
  }
}

:deep(.el-form-item__content) {
  margin-left: 0 !important;
}
</style>